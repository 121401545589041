import Vue from "vue";
import Vuex from "vuex";
import Authentication from "./modules/authentication";
import Assignments from "./modules/assignments";
import AssignmentsStatus from "./modules/assignmentsstatus";
import AssignmentsAdviceStatus from "./modules/assignmentsadvicestatus";
import Consumptions from "./modules/consumptions";
import Employees from "./modules/employees";
import Costcentres from "./modules/costcentres";
import Ccmaintenance from "./modules/ccmaintenance";
import Clipboard from "./modules/clipboard";
import Logging from "./modules/logging";
import { getModule } from "vuex-module-decorators";
import localforage from "localforage";

Vue.use(Vuex);

export const DB_NAME: string = "godelmannservice";
export const DB_STORE_NAME: string = "app_data";
export const HOST_URL: string = "https://my.living-apps.de";
export const CREATE_AUTH_TOKEN_ENDPOINT: string = `${HOST_URL}/gateway/login`;
export const LOGIN_CHECK_ENDPOINT: string = `${HOST_URL}/gateway/tasks/logged_in`;
export const APP_ID_GL_AUFTRAEGE: string = "5d7237c3359aaa99fb7fe0d2";
export const APP_ID_GL_VERBRAUCH: string = "5d70f77cd249bd7c5b7658f4";
export const APP_ID_GL_MITARBEITER: string = "5d723f108dd39dfd41fa3ab4";
export const APP_ID_GL_KOSTENSTELLE: string = "5dc5352c52c37721cc04fad9";
export const APP_ID_GL_MITARBEITER_ZWISCHENABLAGE: string = "608fe20a6bdade295a4f4826";

// Costcentre pinned app
export const APP_ID_GL_WARTUNGEN: string = "5e284908a69bb7ae526b5443";
export const APP_ID_GL_WARTUNGSAUFGABEN: string = "5e2843a4bf69b68638b135ac";
export const MAINTENANCE_GET_URL: string = `${HOST_URL}/gateway/apps/${APP_ID_GL_WARTUNGSAUFGABEN}?template=wartung_pwa_neu`;
export const MAINTENANCE_POST_URL: string = `${HOST_URL}/gateway/apps/${APP_ID_GL_WARTUNGSAUFGABEN}`;


localforage.config({
  name: DB_NAME,
  storeName: DB_STORE_NAME
});
const store = new Vuex.Store({
  state: {
    _production: process.env.NODE_ENV === "production" ? true : false,
    _appVersion: process.env.APP_VERSION || "dev",
    getters: {
      appVersion: (state: any) => {
        return state._appVersion;
      },
      isProduction: (state: any) => {
        return state._production;
      }
    }
  },
  modules: {
    storeAuthentication: Authentication,
    storeAssignments: Assignments,
    storeAssignmentsStatus: AssignmentsStatus,
    storeAssignmentsAdviceStatus: AssignmentsAdviceStatus,
    storeConsumptions: Consumptions,
    storeEmployees: Employees,
    storeCostcentres: Costcentres,
    storeCcmaintenance: Ccmaintenance,
    storeLogging: Logging,
    storeClipboard: Clipboard
  },
  strict: process.env.NODE_ENV !== "production"
});

export default store;
// export const StoreMain = getModule(Main, store);
export const StoreAuthentication = getModule(Authentication, store);
export const StoreAssignments = getModule(Assignments, store);
export const StoreAssignmentsStatus = getModule(AssignmentsStatus, store);
export const StoreAssignmentsAdviceStatus = getModule(AssignmentsAdviceStatus, store);
export const StoreConsumptions = getModule(Consumptions, store);
export const StoreEmployees = getModule(Employees, store);
export const StoreCostcentres = getModule(Costcentres, store);
export const StoreCcmaintenance = getModule(Ccmaintenance, store);
export const StoreLogging = getModule(Logging, store);
export const StoreClipboard = getModule(Clipboard, store);
