





















































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { EventBus } from "@/main";
import {
  LivingAppsAssignmentRecord,
  LivingAppsAssignmentControl,
  LivingAppsLookupItem,
  LivingAppsRecordValue
} from "@/types";
import store from "@/store";
import GlobalUser from "@/mixins/GlobalUser.vue";
import { saveMaintenanceRecordMutation } from "@/store/graphql/SaveMaintenanceRecord.graphql";
import { Url } from "url";
import { mixins } from "vue-class-component";

@Component
export default class MaintenanceDetailForm extends Mixins(GlobalUser) {
  [index: string]: any;

  @Prop() public record?: LivingAppsAssignmentRecord;
  @Prop() public controls?: LivingAppsAssignmentControl | null;

  public formTitel: string = "";
  public formMitarbeiter: string = "";
  public formAnlId: string = "";
  public formBeschreibung: string = "";
  public formPrioritaet: LivingAppsLookupItem[] | null = null;
  public formStatus: LivingAppsLookupItem[] | null = null;

  public isDirty: boolean = false;

  public mounted() {
    this.formTitel = this.getFieldValue("titel");
    this.formMitarbeiter = this.getFieldValue("mitarbeiter");
    this.formAnlId = this.getFieldValue("anl_id");
    this.formBeschreibung = this.getFieldValue("beschreibung");
    this.formPrioritaet = this.getFieldValue("prioritaet");
    this.formStatus = this.getFieldValue("status");
  }

  public get titel(): string {
    return this.formTitel;
  }

  public set titel(value: string) {
    this.formTitel = value;
    this.isDirty = true;
  }

  public get mitarbeiter(): string {
    return this.formMitarbeiter;
  }

  public set mitarbeiter(value: string) {
    this.formMitarbeiter = value;
    this.isDirty = true;
  }

  public get anlId(): string {
    return this.formAnlId;
  }

  public set anlId(value: string) {
    this.formAnlId = value;
    this.isDirty = true;
  }

  public get beschreibung(): string {
    return this.formBeschreibung;
  }

  public set beschreibung(value: string) {
    this.formBeschreibung = value;
    this.isDirty = true;
  }

  public get prioritaet(): LivingAppsLookupItem[] | null {
    if (this.formPrioritaet) {
      return this.formPrioritaet;
    }
    return null;
  }

  public set prioritaet(value: LivingAppsLookupItem[] | null) {
    this.formPrioritaet = value;
    this.isDirty = true;
  }

  public get status(): LivingAppsLookupItem[] | null {
    if (this.formStatus) {
      return this.formStatus;
    }
    return null;
  }

  public set status(value: LivingAppsLookupItem[] | null) {
    this.formStatus = value;
    this.isDirty = true;
  }

  public getControl(name: string): any | null {
    if (this.controls && name in this.controls) {
      return this.controls[name].control;
    }
    return null;
  }

  public getLookupItemForFieldWithKey(
    name: string,
    e: any
  ): LivingAppsLookupItem[] | null {
    const control = this.getControl(name);
    const lupItems: LivingAppsLookupItem[] = [];
    let lupItem: LivingAppsLookupItem;
    if (e) {
      for (lupItem of control.lookupdata) {
        if (lupItem.key === e.target.value) {
          lupItems.push(lupItem);
        }
      }
      return lupItems;
    }
    return null;
  }

  public getFieldValue(name: string): any {
    const control = this.getControl(name);
    if (this.record && control) {
      const rep = this.record[name];
      if (rep) {
        return rep.value;
      }
    }
    return null;
  }

  public isSelected(key: string, name: string): boolean {
    const currLupItems = this[name];
    if (currLupItems && currLupItems.length) {
      for (const currLupItem of currLupItems) {
        if (currLupItem.key === key) {
          return true;
        }
      }
    }
    return false;
  }

  public laRecordWrapper(
    value: string | LivingAppsLookupItem[] | null
  ): LivingAppsRecordValue | null {
    if (value) {
      return { value };
    }
    return null;
  }

  public async save() {
    const data: LivingAppsAssignmentRecord | any = {};
    const mutation = saveMaintenanceRecordMutation;
    data.auftrag_id = this.record ? this.record.auftrag_id : "";
    data.titel = this.laRecordWrapper(this.formTitel);
    data.mitarbeiter = this.laRecordWrapper(this.formMitarbeiter);
    data.anl_id = this.laRecordWrapper(this.formAnlId);
    data.beschreibung = this.laRecordWrapper(this.formBeschreibung);
    data.prioritaet = this.laRecordWrapper(this.formPrioritaet);
    data.status = this.laRecordWrapper(this.formStatus);
    if (this.record) {
      data.id = this.record.id;
      await store.dispatch("storeAssignments/updateRecordData", {
        mutation,
        data,
        app: this,
        eventBus: EventBus
      });
    } else {
      const userSymbol = this.getUserSymbol();
      data.mitarbeiter = this.laRecordWrapper(userSymbol);
      await store.dispatch("storeAssignments/createRecordData", {
        mutation,
        data,
        app: this,
        eventBus: EventBus
      });
    }
  }
}
