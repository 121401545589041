import gql from "graphql-tag";
export const getEmployeeRecordsQuery = gql`
  query GetRecords($authToken: String, $appId: String!) {
    livingapi(authToken: $authToken) {
      viewtemplateData(id: $appId) {
        app {
          ma_id: control(identifier: "ma_id") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          mitarbeiternummer: control(identifier: "mitarbeiternummer") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          kuerzel: control(identifier: "kuerzel") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          vorname: control(identifier: "vorname") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          nachname: control(identifier: "nachname") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          e_mail: control(identifier: "e_mail") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          hauptbereich: control(identifier: "hauptbereich") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          kostensatz: control(identifier: "kostensatz") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          maschinenfuehrer: control(identifier: "maschinenfuehrer") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          records {
            id
            ma_id: value(identifier: "ma_id") {
              ... on ValueTypeString {
                value
              }
            }
            mitarbeiternummer: value(identifier: "mitarbeiternummer") {
              ... on ValueTypeString {
                value
              }
            }
            kuerzel: value(identifier: "kuerzel") {
              ... on ValueTypeString {
                value
              }
            }
            vorname: value(identifier: "vorname") {
              ... on ValueTypeString {
                value
              }
            }
            nachname: value(identifier: "nachname") {
              ... on ValueTypeString {
                value
              }
            }
            e_mail: value(identifier: "e_mail") {
              ... on ValueTypeString {
                value
              }
            }
            hauptbereich: value(identifier: "hauptbereich") {
              ... on ValueTypeString {
                value
              }
            }
            kostensatz: value(identifier: "kostensatz") {
              ... on ValueTypeNumber {
                value
              }
            }
            maschinenfuehrer: value(identifier: "maschinenfuehrer") {
              ... on ValueTypeBool {
                value
              }
            }
          }
        }
      }
    }
  }
`;
