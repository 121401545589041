
































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store, { StoreLogging } from "@/store";
import NoListEntriesMessage from "@/components/NoListEntriesMessage.vue";
import localforage from "localforage";
import { EventBus } from "@/main";
import { stripIgnoredCharacters } from "graphql";
import { StandardAlertData } from "../types";

@Component({
  components: {
    NoListEntriesMessage
  }
})
export default class Logging extends Vue {
  public count: number = 0;

  public itemId(noInc?: boolean): string {
    const id = noInc ? this.count : ++this.count;
    return `item_${id}`;
  }

  public get records(): any[] {
    return StoreLogging.getRecords;
  }

  public handleClick(route: string) {
    this.$router.push(route);
  }

  public async deleteEntries() {
    await StoreLogging.delete();
  }

  public async copyStack(idx: number) {
    const record = StoreLogging.getRecords[idx];
    if (record.data.error.stack) {
      await navigator.clipboard.writeText(record.data.error.stack);
      const opts: StandardAlertData = {
        header: "Stacktrace",
        subHeader: "Stacktrace wurde in die Zwischenablage kopiert.",
        message: ""
      };
      EventBus.$emit("standardError", opts);
    }
  }

  protected mounted() {
    StoreLogging.loadRecords();
  }
}
