// import { LivingAppsEmployeeRecord, LivingAppsEmployeeControl } from "@/types";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store from "@/store";
import localforage from "localforage";

@Module({ store, namespaced: true, name: "storeLogging" })
export default class Employees extends VuexModule {
  public records: any[] = [];
  public lastDate: string = "";
  public _IDB_KEY_RECORDS: string = "logging";

  public get IDB_KEY_RECORDS(): string {
    return this._IDB_KEY_RECORDS;
  }

  public get getRecords(): any[] {
    return this.records;
  }
  public get errorDate(): string {
    return this.lastDate;
  }

  @Mutation
  public setRecordsData(records: any[]) {
    this.records = records;
  }

  @Mutation
  public iSOWithTimezoneString(date: Date) {
    const d: Date = date;
    const dateStr = new Date(d.getTime() - d.getTimezoneOffset() * 60000)
      .toISOString()
      .split(".")[0];
    this.lastDate = dateStr;
  }

  @Action
  public async loadRecords() {
    const records: any[] | null = await localforage.getItem(this.IDB_KEY_RECORDS);
    if (records) {
      this.context.commit("setRecordsData", records);
    }
  }


  @Action
  public async push(data: any) {
    let logData: any[] | null = await localforage.getItem(this.IDB_KEY_RECORDS);
    this.context.commit("iSOWithTimezoneString", new Date());
    const pimpedData: any = {
      date: this.context.getters.errorDate,
      data
    };
    if (!logData) {
      logData = [];
    } else {
      logData = logData.slice(0, 18);
    }
    logData.unshift(pimpedData);
    await localforage.setItem(this.IDB_KEY_RECORDS, logData);
    this.context.commit("setRecordsData", logData);
  }

  @Action
  public async delete() {
    await localforage.removeItem(this.IDB_KEY_RECORDS);
    this.context.commit("setRecordsData", []);
  }
}
