import gql from "graphql-tag";
export const getClipboardRecordsQuery = gql`
  query GetRecords($authToken: String, $template: String, $appId: String!, $params: String) {
    livingapi(authToken: $authToken) {
      viewtemplateData(id: $appId, template: $template, params: $params) {
        app {
          records {
            id
            offline_id: value(identifier: "offline_id") {
              ... on ValueTypeString {
                value
              }
            }
            archiviert: value(identifier: "archiviert") {
              ... on ValueTypeBool {
                value
              }
            }
            permanent: value(identifier: "permanent") {
              ... on ValueTypeBool {
                value
              }
            }
            datum: value(identifier: "datum") {
              ... on ValueTypeDateTime {
                value
              }
            }
            m_id: value(identifier: "m_id") {
              ... on ValueTypeString {
                value
              }
            }
            name_mitarbeiter: value(identifier: "name_mitarbeiter") {
              ... on ValueTypeString {
                value
              }
            }
            record_id: value(identifier: "record_id") {
              ... on ValueTypeString {
                value
              }
            }
            auf_id: value(identifier: "auf_id") {
              ... on ValueTypeString {
                value
              }
            }
            auf_titel: value(identifier: "auf_titel") {
              ... on ValueTypeString {
                value
              }
            }
            notiz: value(identifier: "notiz") {
              ... on ValueTypeString {
                value
              }
            }
            bild: value(identifier: "bild") {
              ... on ValueTypeFile {
                value {
                  id
                  url
                  filename
                  mimetype
                  width
                  height
                  createdat
                }
              }
            }
            sprachnachricht: value(identifier: "sprachnachricht") {
              ... on ValueTypeFile {
                value {
                  id
                  url
                  filename
                  mimetype
                  width
                  height
                  createdat
                }
              }
            }
          }
        }
      }
    }
  }
`;
