import { ApolloClient } from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import { InMemoryCache } from "apollo-cache-inmemory";

export const HTTP_ENDPOINT: string = "https://my.living-apps.de/graphqlapi/";
// export const HTTP_ENDPOINT: string = "http://localhost:80/";
// if (process.env.NODE_ENV === "production") {
//   const HTTP_ENDPOINT: string = "https://godelmann.xist4c.de/graphqlapi/";
// } else {
//   const HTTP_ENDPOINT: string = "https://godelmann-dev.xist4c.de/graphqlapi/";
// }
// export HTTP_ENDPOINT;

// export const HTTP_ENDPOINT: string = "http://10.10.10.48:5000";

export const client = new ApolloClient({
  link: createUploadLink({
    uri: HTTP_ENDPOINT
  }),
  cache: new InMemoryCache({ addTypename: false })
});
