import gql from "graphql-tag";
export const saveClipboardRecordMutation = gql`
  mutation SaveRecord(
    $authToken: String
    $appId: String!
    $id: String
    $template: String
    # $detail: Boolean
    $offline_id: String
    $datum: String
    $m_id: String
    $name_mitarbeiter: String
    $record_id: String
    $auf_id: String
    $auf_titel: String
    $notiz: String
    $bild: InputFieldFile
    $sprachnachricht: InputFieldFile
  ) {
    saveRecord(
      authToken: $authToken
      id: $appId
      template: $template
      # detail: $detail
      input: {
        id: $id
        fields: [
          { identifier: "offline_id", value: $offline_id }
          { identifier: "m_id", value: $m_id }
          { identifier: "record_id", value: $record_id }
          { identifier: "auf_id", value: $auf_id }
          { identifier: "auf_titel", value: $auf_titel }
          { identifier: "name_mitarbeiter", value: $name_mitarbeiter }
          { identifier: "notiz", value: $notiz }
        ]
        fileFields: [
          { identifier: "bild", value: $bild }
          { identifier: "sprachnachricht", value: $sprachnachricht }
        ]
        dateFields: [
            { identifier: "datum", value: $datum }
        ]
      }
    ) {
      record {
        id
        offline_id: value(identifier: "offline_id") {
          ... on ValueTypeString {
            value
          }
        }
        archiviert: value(identifier: "archiviert") {
          ... on ValueTypeBool {
            value
          }
        }
        permanent: value(identifier: "permanent") {
          ... on ValueTypeBool {
            value
          }
        }
        datum: value(identifier: "datum") {
          ... on ValueTypeDateTime {
            value
          }
        }
        m_id: value(identifier: "m_id") {
          ... on ValueTypeString {
            value
          }
        }
        record_id: value(identifier: "record_id") {
          ... on ValueTypeString {
            value
          }
        }
        auf_id: value(identifier: "auf_id") {
          ... on ValueTypeString {
            value
          }
        }
        auf_titel: value(identifier: "auf_titel") {
          ... on ValueTypeString {
            value
          }
        }
        bild: value(identifier: "bild") {
          ... on ValueTypeFile {
            value {
              id
              url
              filename
              mimetype
              width
              height
              createdat
            }
          }
        }
        sprachnachricht: value(identifier: "sprachnachricht") {
          ... on ValueTypeFile {
            value {
              id
              url
              filename
              mimetype
              createdat
            }
          }
        }
      }
    }
  }
`;
