import Vue from "vue";
import { IonicVueRouter } from "@ionic/vue";
import Main from "./views/Main.vue";
import Maintenance from "./views/Maintenance.vue";
import MaintenanceDetail from "./views/MaintenanceDetail.vue";
import Repairs from "./views/Repairs.vue";
import RepairsDetail from "./views/RepairsDetail.vue";
import Employees from "./views/Employees.vue";
import GrabBag from "./views/GrabBag.vue";
import Reports from "./views/Reports.vue";
import ReportsDetail from "./views/ReportsDetail.vue";
import Consumptions from "./views/Consumptions.vue";
import ActiveAssignment from "./views/ActiveAssignment.vue";
import CcmaintenanceDetail from "./views/CcmaintenanceDetail.vue";
import Logging from "./views/Logging.vue";

import TakeFotoElement from "./components/TakeFotoElement.vue";
import ScanQrCodeElement from "./components/ScanQrCodeElement.vue";
import SpeechToTextElement from "./components/SpeechToTextElement.vue";

Vue.use(IonicVueRouter);

export default new IonicVueRouter({
  mode: "history",
  routes: [
    { path: "/", redirect: "main" },
    {
      path: "/main",
      name: "main",
      component: Main
    },
    {
      path: "/maintenance",
      name: "maintenance",
      component: Maintenance
    },
    {
      path: "/maintenance_detail/:id",
      name: "maintenance_detail",
      component: MaintenanceDetail
    },
    {
      path: "/repairs",
      name: "repairs",
      component: Repairs
    },
    {
      path: "/repairs_detail/:id",
      name: "repairs_detail",
      component: RepairsDetail
    },
    {
      path: "/employees/:id/:type",
      name: "employees",
      component: Employees
    },
    {
      path: "/grab_bag",
      name: "grab_bag",
      component: GrabBag
    },
    {
      path: "/reports/:id/:type",
      name: "reports",
      component: Reports
    },
    {
      path: "/reports_detail/:id/:report_id/:type",
      name: "reports_detail",
      component: ReportsDetail
    },
    {
      path: "/consumptions/:id/:type",
      name: "consumptions",
      component: Consumptions
    },
    {
      path: "/active_assignment",
      name: "active_assignment",
      component: ActiveAssignment
    },
    {
      path: "/cc_maintenance",
      name: "cc_maintenance",
      component: CcmaintenanceDetail
    },
    {
      path: "/logging",
      name: "logging",
      component: Logging
    }
    // {
    //   path: "/status",
    //   name: "status",
    //   component: Status
    // }
    // {
    //   path: "/repairs",
    //   name: "repairs",
    //   component: Repairs
    // },
    // {
    //   path: "/maintenance",
    //   name: "maintenance",
    //   component: Maintenance
    // },
    // {
    //   path: "/parts",
    //   name: "parts",
    //   component: Parts
    // },
    // {
    //   path: "/pgphoto",
    //   name: "pgphoto",
    //   component: TakeFotoElement
    // },
    // {
    //   path: "/pgqrcode",
    //   name: "pgqrcode",
    //   component: ScanQrCodeElement
    // },
    // {
    //   path: "/pgspeech",
    //   name: "pgspeech",
    //   component: SpeechToTextElement
    // }
  ]
});
