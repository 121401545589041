import gql from "graphql-tag";
export const saveGrapBagRecordMutation = gql`
  mutation SaveRecord(
    $authToken: String
    $appId: String!
    $id: String
    $art_id: String
    $offline_id: String
    $artikelnummer: String
    $bezeichnung: String
    $artikeltyp: String
    $lagerplatz: String
    $lap_id: String
    $bestand: Int
    $auftrag_id: String
    $auftrag_bezeichnung: String
    $mitarbeiter_id: String
    $mitarbeitername: String
    $material_geholt_am: String
    $artikelmenge: Int
  ) {
    saveRecord(
      authToken: $authToken
      id: $appId
      input: {
        id: $id
        fields: [
          { identifier: "art_id", value: $art_id }
          { identifier: "offline_id", value: $offline_id }
          { identifier: "artikelnummer", value: $artikelnummer }
          { identifier: "bezeichnung", value: $bezeichnung }
          { identifier: "artikeltyp", value: $artikeltyp }
          { identifier: "lagerplatz", value: $lagerplatz }
          { identifier: "lap_id", value: $lap_id }
          { identifier: "auftrag_id", value: $auftrag_id }
          { identifier: "auftrag_bezeichnung", value: $auftrag_bezeichnung }
          { identifier: "mitarbeiter_id", value: $mitarbeiter_id }
          { identifier: "mitarbeitername", value: $mitarbeitername }
        ]
        numberFields: [
          { identifier: "bestand", valueInt: $bestand }
          { identifier: "artikelmenge", valueInt: $artikelmenge }
        ]
        dateFields: [{ identifier: "material_geholt_am", value: $material_geholt_am }]
      }
    ) {
      record {
        id
        art_id: value(identifier: "art_id") {
          ... on ValueTypeString {
            value
          }
        }
        offline_id: value(identifier: "offline_id") {
          ... on ValueTypeString {
            value
          }
        }
        artikelnummer: value(identifier: "artikelnummer") {
          ... on ValueTypeString {
            value
          }
        }
        bezeichnung: value(identifier: "bezeichnung") {
          ... on ValueTypeString {
            value
          }
        }
        artikeltyp: value(identifier: "artikeltyp") {
          ... on ValueTypeString {
            value
          }
        }
        lagerplatz: value(identifier: "lagerplatz") {
          ... on ValueTypeString {
            value
          }
        }
        lap_id: value(identifier: "lap_id") {
          ... on ValueTypeString {
            value
          }
        }
        bestand: value(identifier: "bestand") {
          ... on ValueTypeNumber {
            value
          }
        }
        auftrag_id: value(identifier: "auftrag_id") {
          ... on ValueTypeString {
            value
          }
        }
        auftrag_bezeichnung: value(identifier: "auftrag_bezeichnung") {
          ... on ValueTypeString {
            value
          }
        }
        mitarbeiter_id: value(identifier: "mitarbeiter_id") {
          ... on ValueTypeString {
            value
          }
        }
        mitarbeitername: value(identifier: "mitarbeitername") {
          ... on ValueTypeString {
            value
          }
        }
        material_geholt_am: value(identifier: "material_geholt_am") {
          ... on ValueTypeDateTime {
            value
          }
        }
        artikelmenge: value(identifier: "artikelmenge") {
          ... on ValueTypeNumber {
            value
          }
        }
      }
    }
  }
`;
