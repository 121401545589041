import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store, { CREATE_AUTH_TOKEN_ENDPOINT } from "@/store";
import { createGraphqlAuthToken } from "@/store/graphql/CreateGraphqlAuthToken.graphql";
import localforage from "localforage";
import { client } from "@/utils/client";
import { LivingAppsEmployeeRecord } from "@/types";

@Module({ store, namespaced: true, name: "storeAuthentication" })
export default class Authentification extends VuexModule {
  public authToken: string = "";
  public graphqlAuthToken: string = "";
  public username: string = "";
  public costcentre: string = "";
  public costcentreOperator: LivingAppsEmployeeRecord | null = null;

  public _IDB_KEY_AUTH_TOKEN: string = "auth_token";
  public _IDB_KEY_GRAPHQL_AUTH_TOKEN: string = "graphql_auth_token";
  public _IDB_KEY_USERNAME: string = "username";
  public _IDB_KEY_COSTCENTRE: string = "costcentre";
  public _IDB_KEY_COSTCENTRE_OPERATOR: string = "costcentre_operator";

  public get IDB_KEY_AUTH_TOKEN(): string {
    return this._IDB_KEY_AUTH_TOKEN;
  }

  public get IDB_KEY_GRAPHQL_AUTH_TOKEN(): string {
    return this._IDB_KEY_GRAPHQL_AUTH_TOKEN;
  }

  public get IDB_KEY_USERNAME(): string {
    return this._IDB_KEY_USERNAME;
  }

  public get IDB_KEY_COSTCENTRE(): string {
    return this._IDB_KEY_COSTCENTRE;
  }

  public get IDB_KEY_COSTCENTRE_OPERATOR(): string {
    return this._IDB_KEY_COSTCENTRE_OPERATOR;
  }

  public get getGraphqlAuthToken(): string {
    return this.graphqlAuthToken;
  }

  public get getAuthToken(): string {
    return this.authToken;
  }

  public get getUsername(): string {
    return this.username;
  }

  public get getCostcentre(): string {
    return this.costcentre;
  }

  public get getCostcentreOperator(): LivingAppsEmployeeRecord | null {
    return this.costcentreOperator;
  }

  @Mutation
  public async setAuthToken(attrs: any) {
    this.authToken = attrs.auth_token;

    if (attrs.newToken) {
      await localforage.setItem(this._IDB_KEY_AUTH_TOKEN, attrs.auth_token);
    }
    if (attrs.app) {
      attrs.app.handleLoginStatusMessage();
    }
  }

  @Mutation
  public async setGraphqlAuthToken(attrs: any) {
    this.graphqlAuthToken = attrs.graphql_auth_token;
    if (attrs.newToken) {
      await localforage.setItem(this._IDB_KEY_GRAPHQL_AUTH_TOKEN, attrs.graphql_auth_token);
    }
    if (attrs.app) {
      attrs.app.handleLoginStatusMessage();
    }
  }

  @Mutation
  public async setUsername(attrs: any) {
    this.username = attrs.username;
    if (attrs.newToken) {
      await localforage.setItem(this._IDB_KEY_USERNAME, attrs.username);
    }
    if (attrs.app) {
      attrs.app.handleLoginStatusMessage();
    }
  }

  @Mutation
  public async setCostcentre(attrs: any) {
    if (attrs.costcentre) {
      this.costcentre = attrs.costcentre;
      if (attrs.newToken) {
        await localforage.setItem(this._IDB_KEY_COSTCENTRE, attrs.costcentre);
      }
    }
  }

  @Mutation
  public async setCostcentreOperator(attrs: any) {
    if (attrs.employee) {
      this.costcentreOperator = attrs.employee;
      if (attrs.newToken) {
        await localforage.setItem(this._IDB_KEY_COSTCENTRE_OPERATOR, attrs.employee);
      }
    }
  }

  @Action
  public async requestAuthToken(attrs: any) {
    const gqlRes: any = await client.query({
      fetchPolicy: "no-cache",
      query: createGraphqlAuthToken,
      variables: {
        username: attrs.username,
        password: attrs.password
      }
    });
    const req = await fetch(CREATE_AUTH_TOKEN_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        username: attrs.username,
        password: attrs.password
      })
    });
    const res = await req.json();
    if (gqlRes.data && res.status === "success") {
      this.context.commit("setGraphqlAuthToken", {
        graphql_auth_token: gqlRes.data.createToken.token,
        newToken: true,
        app: attrs.app
      });
      this.context.commit("setAuthToken", {
        auth_token: res.auth_token,
        newToken: true,
        app: attrs.app
      });
      this.context.commit("setUsername", {
        username: attrs.username,
        newToken: true,
        app: attrs.app
      });
      this.context.commit("setCostcentre", {
        costcentre: attrs.costcentre,
        newToken: true
      });
    } else {
      attrs.app.handleLoginStatusMessage(res);
    }
  }

  @Action
  public async currentAuthToken() {
    const authToken = await localforage.getItem(this._IDB_KEY_AUTH_TOKEN);
    const graphqlAuthToken = await localforage.getItem(this._IDB_KEY_GRAPHQL_AUTH_TOKEN);
    const username = await localforage.getItem(this._IDB_KEY_USERNAME);
    if (authToken && graphqlAuthToken && username) {
      this.context.commit("setAuthToken", { auth_token: authToken });
      this.context.commit("setGraphqlAuthToken", { graphql_auth_token: graphqlAuthToken });
      this.context.commit("setUsername", { username });
      return {
        authToken,
        graphqlAuthToken
      };
    }
    return null;
  }

  @Action
  public async currentCostcentre() {
    const costcentre = await localforage.getItem(this._IDB_KEY_COSTCENTRE);
    const costcentreOperator = await localforage.getItem(this._IDB_KEY_COSTCENTRE_OPERATOR);
    if (costcentre) {
      this.context.commit("setCostcentre", { costcentre });
    }
    if (costcentreOperator) {
      this.context.commit("setCostcentreOperator", { employee: costcentreOperator });
    }
  }
}
