import "reflect-metadata";
import Vue from "vue";
import App from "./App.vue";
import Ionic from "@ionic/vue";
import "@ionic/core/css/core.css";
import "@ionic/core/css/ionic.bundle.css";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import { Period } from "@/types";

Vue.config.devtools = true;
Vue.config.productionTip = false;

Vue.use(Ionic);

export const PeriodTypes: Period = Object.freeze({
  d: "taeglich",
  w: "woechentlich",
  m: "monatlich",
  qy: "vierteljaehrlich",
  hy: "halbjaehrlich",
  y: "jaehrlich"
});

export const EventBus = new Vue();

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
