import gql from "graphql-tag";
export const archiveClipboardRecordMutation = gql`
  mutation SaveRecord(
    $authToken: String
    $appId: String!
    $id: String
    $template: String
  ) {
    saveRecord(
      authToken: $authToken
      id: $appId
      template: $template
      input: {
        id: $id
        booleanFields: [{ identifier: "archiviert", value: true }]
      }
    ) {
      record {
        id
        offline_id: value(identifier: "offline_id") {
          ... on ValueTypeString {
            value
          }
        }
        archiviert: value(identifier: "archiviert") {
          ... on ValueTypeBool {
            value
          }
        }
        datum: value(identifier: "datum") {
          ... on ValueTypeDateTime {
            value
          }
        }
        m_id: value(identifier: "m_id") {
          ... on ValueTypeString {
            value
          }
        }
        record_id: value(identifier: "record_id") {
          ... on ValueTypeString {
            value
          }
        }
        auf_id: value(identifier: "auf_id") {
          ... on ValueTypeString {
            value
          }
        }
        auf_titel: value(identifier: "auf_titel") {
          ... on ValueTypeString {
            value
          }
        }
        bild: value(identifier: "bild") {
          ... on ValueTypeFile {
            value {
              id
              url
              filename
              mimetype
              width
              height
              createdat
            }
          }
        }
        sprachnachricht: value(identifier: "sprachnachricht") {
          ... on ValueTypeFile {
            value {
              id
              url
              filename
              mimetype
              createdat
            }
          }
        }
      }
    }
  }
`;
