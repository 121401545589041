
































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { StoreAssignments, StoreAssignmentsStatus } from "@/store";
import { LivingAppsAssignmentRecord, LivingAppsAssignmentControl } from "@/types";
import MaintenanceDetailData from "@/components/MaintenanceDetailData.vue";
import MaintenanceDetailForm from "@/components/MaintenanceDetailForm.vue";
import AssignmentDetailNav from "@/components/AssignmentDetailNav.vue";
import ActiveAssignmentButton from "@/components/ActiveAssignmentButton.vue";
import ClipboardButton from "@/components/ClipboardButton.vue";
import LoginForm from "@/components/LoginForm.vue";
import { AlertController } from "@ionic/vue/dist/types/controllers";
import { OverlayController, AlertOptions } from "@ionic/core";
import { EventBus } from "@/main";

@Component({
  components: {
    MaintenanceDetailData,
    MaintenanceDetailForm,
    AssignmentDetailNav,
    ActiveAssignmentButton,
    ClipboardButton,
  },
})
export default class RepairsDetail extends Vue {
  public edit: boolean = false;
  public storeAssignments = StoreAssignments;
  public record?: LivingAppsAssignmentRecord | null = null;
  public controls?: LivingAppsAssignmentControl | null;
  public type: string = "repairs";

  public get detailNavRoutes() {
    const id: string = this.$route.params.id;
    const type: string = this.type;
    return {
      infos: { name: `${this.type}_detail`, params: { id, type } },
      reports: { name: "reports", params: { id, type } },
      consumptions: { name: "consumptions", params: { id, type } },
      employees: { name: "employees", params: { id, type } },
      edit: { name: "", params: { id, type } },
    };
  }

  public get filterRecords() {
    for (const record of this.storeAssignments.getRecords) {
      if (record.id === this.$route.params.id) {
        this.record = record;
      }
    }
    return this.record;
  }

  public handleControls() {
    this.controls = this.storeAssignments.getControls;
  }

  public handleClick(route: string) {
    this.$router.push(route);
  }

  public editRecord() {
    this.edit = true;
  }

  public formFinish() {
    const refForm: any = this.$refs.repairsFormComp;
    if (refForm.isDirty) {
      EventBus.$emit("dirtyForm", this, refForm);
    } else {
      this.edit = false;
    }
  }

  public formCancel() {
    const refForm: any = this.$refs.repairsFormComp;
    this.edit = false;
    refForm.isDirty = false;
  }

  public formSaved() {
    const refForm: any = this.$refs.repairsFormComp;
    this.edit = false;
    refForm.isDirty = false;
  }

  public async markAsRead() {
    if (this.record && this.record.id) {
      await StoreAssignmentsStatus.updateRecordAsRead(this.record.id);
    }
  }

  protected mounted() {
    this.handleControls();
    this.markAsRead();
  }
}
