








































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store, { StoreAssignments } from "../store";
import {
  LivingAppsAssignmentRecord,
  LivingAppsAssignmentControl
} from "../types";
import MaintenanceDetailForm from "@/components/MaintenanceDetailForm.vue";
import AssignmensList from "@/components/AssignmentsList.vue";
import { EventBus } from "@/main";

@Component({
  components: {
    MaintenanceDetailForm,
    AssignmensList
  }
})
export default class Repairs extends Vue {
  public storeAssignments = StoreAssignments;
  public controls?: LivingAppsAssignmentControl | null;
  public edit: boolean = false;

  public get records(): LivingAppsAssignmentRecord[] {
    const realRecords: LivingAppsAssignmentRecord[] = [];
    const records: LivingAppsAssignmentRecord[] = this.storeAssignments
      .getRecords;
    for (const record of records) {
      if (record.typ.value[0].key === "reparatur") {
        realRecords.push(record);
      }
    }
    return realRecords;
  }

  public handleControls() {
    this.controls = this.storeAssignments.getControls;
  }

  public handleClick(route: string) {
    this.$router.push(route);
  }

  public newRecord() {
    this.edit = true;
  }

  public formFinish() {
    const refForm: any = this.$refs.repairsFormComp;
    if (refForm.isDirty) {
      EventBus.$emit("dirtyForm", this, refForm);
    } else {
      this.edit = false;
    }
  }

  public formCancel() {
    const refForm: any = this.$refs.repairsFormComp;
    this.edit = false;
    refForm.isDirty = false;
  }

  public formSaved() {
    const refForm: any = this.$refs.repairsFormComp;
    this.edit = false;
    refForm.isDirty = false;
  }

  protected mounted() {
    this.handleControls();
  }
}
