import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store from "@/store";
import localforage from "localforage";

@Module({ store, namespaced: true, name: "storeAssignmentsStatus" })
export default class AssignmentsStatus extends VuexModule {
  public records: any = null;
  public _IDB_KEY_RECORDS: string = "assignmentsstatus";

  public get IDB_KEY_RECORDS(): string {
    return this._IDB_KEY_RECORDS;
  }

  public get getRecords() {
    return this.records;
  }

  @Mutation
  public setRecords(records: any) {
    this.records = records;
  }

  @Mutation
  public updateAsRead(recordId: string) {
    const records: any = this.records;
    if (records[recordId]) {
      records[recordId] = false;
    }
    this.records = records;
  }

  @Action
  public async initRecords() {
    const statusRecords: any = await localforage.getItem(this.context.getters.IDB_KEY_RECORDS) || null;
    this.context.commit("setRecords", statusRecords);
  }

  @Action
  public async updateRecordAsRead(recordId: string) {
    this.updateAsRead(recordId);
    await localforage.setItem(this.context.getters.IDB_KEY_RECORDS, this.context.getters.getRecords);
  }

  @Action
  public async updateRecords(records: any) {
    await localforage.setItem(this.context.getters.IDB_KEY_RECORDS, records);
    this.setRecords(records);
  }
}
