/* tslint:disable:no-console */

import { register } from "register-service-worker";
import { EventBus } from "@/main";
import { StandardAlertData } from "@/types";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
        "For more details, visit https://goo.gl/AFskqB"
      );
      /**
       * Filling up the reports store with data from server.
       * If not online we try to get it from local storage.
       */
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated() {
      const data: StandardAlertData = {
        header: "Update der App",
        subHeader: "Für diese App wurde ein Update bereitgestellt.",
        message: `Wenn möglich beenden Sie die App,
        so das sie auch im Hintergrund nicht mehr aktiv ist.
        Danach die App wieder starten.`
      };
      EventBus.$emit("standardError", data);
    },
    offline() {
      console.log("No internet connection found. App is running in offline mode.");
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    }
  });
}
