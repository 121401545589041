import gql from "graphql-tag";
export const saveConsumptionsRecordMutation = gql`
  mutation SaveRecord(
    $authToken: String
    $appId: String!
    $id: String
    $template: String
    $detail: Boolean
    $art_id: String
    $auftrag_id: String
    $offline_id: String
    $artikelnummer: String
    $bezeichnung: String
    $artikeltyp: String
    $lagerplatz: String
    $lap_id: String
    $bestand: Int
    $auftrag_bezeichnung: String
    $mitarbeiter_id: String
    $mitarbeitername: String
    $mitarbeiterstatus: String
    $mitarbeiterstatustext: String
    $material_geholt_am: String
    $artikelmenge: Int
    $begin: String
    $ende: String
    $dauer: Float
    $audio: InputFieldFile
    $inaktiv2: Boolean = false
  ) {
    saveRecord(
      authToken: $authToken
      id: $appId
      template: $template
      detail: $detail
      input: {
        id: $id
        fields: [
          { identifier: "art_id", value: $art_id }
          { identifier: "auftrag_id", value: $auftrag_id }
          { identifier: "offline_id", value: $offline_id }
          { identifier: "artikelnummer", value: $artikelnummer }
          { identifier: "bezeichnung", value: $bezeichnung }
          { identifier: "artikeltyp", value: $artikeltyp }
          { identifier: "lagerplatz", value: $lagerplatz }
          { identifier: "lap_id", value: $lap_id }
          { identifier: "auftrag_bezeichnung", value: $auftrag_bezeichnung }
          { identifier: "mitarbeiter_id", value: $mitarbeiter_id }
          { identifier: "mitarbeitername", value: $mitarbeitername }
          { identifier: "mitarbeiterstatus", value: $mitarbeiterstatus }
          { identifier: "mitarbeiterstatustext", value: $mitarbeiterstatustext }
        ]
        booleanFields: [{ identifier: "inaktiv2", value: $inaktiv2 }]
        fileFields: [{ identifier: "audio", value: $audio }]
        dateFields: [
          { identifier: "material_geholt_am", value: $material_geholt_am }
          { identifier: "begin", value: $begin }
          { identifier: "ende", value: $ende }
        ]
        numberFields: [
          { identifier: "bestand", valueInt: $bestand }
          { identifier: "artikelmenge", valueInt: $artikelmenge }
          { identifier: "dauer", valueFloat: $dauer }
        ]
      }
    ) {
      record {
        id
        art_id: value(identifier: "art_id") {
          ... on ValueTypeString {
            value
          }
        }
        auftrag_id: value(identifier: "auftrag_id") {
          ... on ValueTypeString {
            value
          }
        }
        offline_id: value(identifier: "offline_id") {
          ... on ValueTypeString {
            value
          }
        }
        artikelnummer: value(identifier: "artikelnummer") {
          ... on ValueTypeString {
            value
          }
        }
        bezeichnung: value(identifier: "bezeichnung") {
          ... on ValueTypeString {
            value
          }
        }
        artikeltyp: value(identifier: "artikeltyp") {
          ... on ValueTypeString {
            value
          }
        }
        lagerplatz: value(identifier: "lagerplatz") {
          ... on ValueTypeString {
            value
          }
        }
        lap_id: value(identifier: "lap_id") {
          ... on ValueTypeString {
            value
          }
        }
        bestand: value(identifier: "bestand") {
          ... on ValueTypeNumber {
            value
          }
        }
        auftrag_bezeichnung: value(identifier: "auftrag_bezeichnung") {
          ... on ValueTypeString {
            value
          }
        }
        mitarbeiter_id: value(identifier: "mitarbeiter_id") {
          ... on ValueTypeString {
            value
          }
        }
        mitarbeitername: value(identifier: "mitarbeitername") {
          ... on ValueTypeString {
            value
          }
        }
        mitarbeiterstatus: value(identifier: "mitarbeiterstatus") {
          ... on ValueTypeString {
            value
          }
        }
        mitarbeiterstatustext: value(identifier: "mitarbeiterstatustext") {
          ... on ValueTypeString {
            value
          }
        }
        material_geholt_am: value(identifier: "material_geholt_am") {
          ... on ValueTypeDate {
            value
          }
        }
        artikelmenge: value(identifier: "artikelmenge") {
          ... on ValueTypeNumber {
            value
          }
        }
        begin: value(identifier: "begin") {
          ... on ValueTypeDate {
            value
          }
        }
        ende: value(identifier: "ende") {
          ... on ValueTypeDate {
            value
          }
        }
        dauer: value(identifier: "dauer") {
          ... on ValueTypeNumber {
            value
          }
        }
        audio: value(identifier: "audio") {
          ... on ValueTypeFile {
            value {
              id
              url
              filename
              mimetype
              createdat
            }
          }
        }
        inaktiv2: value(identifier: "inaktiv2") {
          ... on ValueTypeBool {
            value
          }
        }
      }
    }
  }
`;
