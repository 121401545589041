import { LivingAppsConsumptionRecord, LivingAppsConsumptionControl } from "@/types";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { client } from "@/utils/client";
import store, { APP_ID_GL_VERBRAUCH, StoreAuthentication } from "@/store";
import localforage from "localforage";
import uniqid from "uniqid";
import { getConsumptionRecordsQuery } from "@/store/graphql/GetConsumptionRecords.graphql";
import { deleteRecordMutation } from "@/store/graphql/DeleteRecords.graphql";

@Module({ store, namespaced: true, name: "storeConsumptions" })
export default class Consumptions extends VuexModule {
  public records: LivingAppsConsumptionRecord[] = [];
  public controls: LivingAppsConsumptionControl | null = null;
  public _IDB_KEY_RECORDS: string = "consumptions";
  public _IDB_KEY_CONTROLS: string = "consumptions_controls";

  public get IDB_KEY_RECORDS(): string {
    return this._IDB_KEY_RECORDS;
  }

  public get IDB_KEY_CONTROLS(): string {
    return this._IDB_KEY_CONTROLS;
  }

  public get recordsLength(): number {
    return this.records.length;
  }

  public get getRecords(): LivingAppsConsumptionRecord[] {
    return this.records;
  }

  public get getConsumptionsForAssignment(): any {
    return (assignmentId: string): LivingAppsConsumptionRecord[] | null => {
      const records = [];
      for (const record of this.getRecords) {
        if (record.auftrag_id) {
          if ((record.artikelmenge || record.dauer) && record.auftrag_id.value === assignmentId) {
            records.push(record);
          }
        }
      }
      return records;
    };
  }

  public get getArticleForStoreId(): (storeId: string | null) => LivingAppsConsumptionRecord | null {
    return (storeId: string | null): LivingAppsConsumptionRecord | null => {
      if (storeId) {
        for (const record of this.getRecords) {
          if (record.lap_id && !record.mitarbeiter_id) {
            return record;
          }
        }
      }
      return null;
    };
  }

  public get getOpenTimeConsumptionForAssignment(): (
    assignmentId: string,
    employeeId: string
  ) => LivingAppsConsumptionRecord | null {
    return (assignmentId: string, employeeId?: string): LivingAppsConsumptionRecord | null => {
      const records = this.context.getters.getRecords as LivingAppsConsumptionRecord[];
      for (const record of records) {
        if (record.auftrag_id && record.auftrag_id.value === assignmentId) {
          if (StoreAuthentication.getCostcentre) {
            if (!record.ende && record.begin) {
              return record;
            }
          } else {
            if (
              !record.ende &&
              record.begin &&
              record.mitarbeiter_id &&
              record.mitarbeiter_id.value === employeeId
            ) {
              return record;
            }
          }
        }
      }
      return null;
    };
  }

  public get getEmployeeAssignmentBinding(): any {
    return (assignmentId: string, employeeId: string) => {
      const records = this.context.getters.getRecords as LivingAppsConsumptionRecord[];
      for (const record of records) {
        if (
          record.auftrag_id &&
          record.auftrag_id.value === assignmentId &&
          record.mitarbeiter_id &&
          record.mitarbeiter_id.value === employeeId &&
          !record.begin &&
          !record.ende &&
          !record.dauer &&
          !record.art_id
        ) {
          return record;
        }
      }
      return null;
    };
  }

  public get getRecordsForSymbol(): any {
    return (symbol: string): LivingAppsConsumptionRecord[] => {
      const userRecords = [];
      for (const record of this.getRecords) {
        if (
          record.mitarbeitername &&
          (record.mitarbeitername.value as string).trim().toLowerCase() === symbol
        ) {
          userRecords.push(record);
        }
      }
      return userRecords;
    };
  }

  public get getAssignmentIdsByEmployeeId(): any {
    return (employeeId: string): string[] => {
      const ids: string[] = [];
      const records = this.context.getters.getRecords as LivingAppsConsumptionRecord[];
      for (const record of records) {
        if (record.mitarbeiter_id && record.mitarbeiter_id.value === employeeId) {
          if (
            record.auftrag_id &&
            !record.begin &&
            !record.dauer &&
            !record.ende &&
            !record.art_id &&
            (record.inaktiv2 && record.inaktiv2.value === false || !record.inaktiv2)
          ) {
            ids.push(record.auftrag_id.value as string);
          }
        }
      }
      return ids;
    };
  }

  public get getControls(): LivingAppsConsumptionControl | null {
    return this.controls;
  }

  @Mutation
  public setRecordsData(records: LivingAppsConsumptionRecord[]) {
    this.records = records;
  }

  @Mutation
  public setControlsData(controls: LivingAppsConsumptionControl | null) {
    this.controls = controls;
  }

  @Action
  public async getDataFromEndpoint(attrs: any) {
    const res = await client.query({
      context: {
        headers: {
          "x-graphql-req-name": "consumptions",
          "x-graphql-req-renew-cache": true
        }
      },
      fetchPolicy: "no-cache",
      query: getConsumptionRecordsQuery,
      variables: {
        authToken: StoreAuthentication.getGraphqlAuthToken,
        appId: APP_ID_GL_VERBRAUCH
      }
    });
    const recValue = res.data.livingapi.viewtemplateData.app.records || [];
    const appData = res.data.livingapi.viewtemplateData.app;
    let realRecords: LivingAppsConsumptionRecord[];

    if (res.data.fromCache) {
      realRecords = recValue;
    } else {
      const localRecords: LivingAppsConsumptionRecord[] | null = await localforage.getItem(
        this.IDB_KEY_RECORDS
      );
      const offlineRecords: LivingAppsConsumptionRecord[] = [];
      if (localRecords) {
        for (const record of localRecords) {
          if (record.id && record.id.includes("__cached__")) {
            offlineRecords.push(record);
          }
        }
      }
      realRecords = [...offlineRecords, ...recValue];
      await localforage.setItem(this.IDB_KEY_RECORDS, realRecords);
    }
    this.context.commit("setRecordsData", realRecords);
    const controls: LivingAppsConsumptionControl = {} as LivingAppsConsumptionControl;
    for (const key in appData) {
      if (key !== "records") {
        if (appData.hasOwnProperty(key)) {
          controls[key] = appData[key];
        }
      }
    }
    const ctrlValue = controls;
    if (!res.data.fromCache) {
      await localforage.setItem(this.IDB_KEY_CONTROLS, ctrlValue);
    }
    this.context.commit("setControlsData", ctrlValue);
  }

  @Action
  public async updateRecordData(attrs: any) {
    // Update indexedDB record data with form data.
    const idbData: any = await localforage.getItem(this.IDB_KEY_RECORDS);
    const mutation = attrs.mutation;
    let record: LivingAppsConsumptionRecord;
    for (record of idbData) {
      if (record.id && !record.id.includes("__cached__") && attrs.data.id.includes("__cached__")) {
        if (record.offline_id && record.offline_id.value === attrs.data.offline_id.value) {
          attrs.data.id = record.id;
        }
      }
      if (record.id === attrs.data.id) {
        for (const key in attrs.data) {
          if (attrs.data.hasOwnProperty(key)) {
            const d = attrs.data[key];
            record[key] = d;
          }
        }
        // Now we store the altered record data back into indexedDB.
        const recs = await localforage.setItem(this.IDB_KEY_RECORDS, idbData);
        this.context.commit("setRecordsData", recs);
        attrs.app.$emit("saved");
        // Try to store the data to livingapps app.
        await this.storeRecordToLivingApps({
          mutation,
          record,
          eventBus: attrs.eventBus,
          app: attrs.app
        });
        break;
      }
    }
  }

  @Action
  public async createRecordData(attrs: any) {
    // Update indexedDB record data with form data.
    const record: LivingAppsConsumptionRecord = attrs.data;
    const mutation = attrs.mutation;
    record.id = uniqid("__cached__") as string;
    record.offline_id = { value: record.id };
    const idbData: any = await localforage.getItem(this.IDB_KEY_RECORDS);
    idbData.unshift(record);
    const recs = await localforage.setItem(this.IDB_KEY_RECORDS, idbData);

    this.context.commit("setRecordsData", recs);
    attrs.app.$emit("saved");

    // Try to store the data to livingapps app.
    await this.storeRecordToLivingApps({
      mutation,
      record,
      eventBus: attrs.eventBus,
      app: attrs.app
    });
  }

  @Action
  public async deleteRecordData(attrs: any) {
    const recordIds = attrs.recordIds;
    const idbData: LivingAppsConsumptionRecord[] | null = await localforage.getItem(this.IDB_KEY_RECORDS);
    const realData = [];
    const realRecordIds = [];
    if (idbData) {
      for (const record of idbData) {
        if (
          recordIds.includes(record.id) ||
          (record.offline_id && recordIds.includes(record.offline_id.value))
        ) {
          realRecordIds.push(record.id);
        } else {
          realData.push(record);
        }
      }
      await localforage.setItem(this.IDB_KEY_RECORDS, realData);
      this.context.commit("setRecordsData", realData);
      await this.deleteRecordsFromLivingApps({
        recordIds: realRecordIds,
        app: attrs.app,
        eventBus: attrs.eventBus
      });
    }
  }

  @Action
  public async storeRecordToLivingApps(attrs: any) {
    const record = { ...attrs.record };
    const mutation = attrs.mutation;
    let recordCacheId: string | null = null;
    let template: string = "mutation_update_mobile";
    let detail: boolean = true;
    if (record.id.includes("__cached__")) {
      recordCacheId = record.id;
      record.id = null;
      template = "mutation_insert_mobile";
      detail = false;
    }

    // Sending mutation to the grapql server.
    const variables: any = {
      authToken: StoreAuthentication.getGraphqlAuthToken,
      appId: APP_ID_GL_VERBRAUCH,
      id: record.id,
      template,
      detail
    };
    Object.keys(record).map((key: string) => {
      if (key !== "id") {
        if (record[key]) {
          if (record[key].value instanceof File) {
            variables[key] = { file: record[key].value };
          } else {
            variables[key] = record[key].value;
          }
        } else {
          if (key === "audio") {
            variables[key] = { file: null };
          } else {
            variables[key] = null;
          }
        }
      }
    });
    const res = await client.mutate({
      context: {
        headers: {
          "x-graphql-req-name": "consumptions",
          "x-graphql-req-renew-cache": true,
          ...(() => {
            return recordCacheId ? { "x-data-cache-id": recordCacheId } : null;
          })(),
          ...(() => {
            if (record.begin && !record.ende) {
              return { "x-skip-bgsync": true };
            }
            return { "x-skip-bgsync": false };
          })()
        }
      },
      fetchPolicy: "no-cache",
      mutation,
      variables
    });

    // Updating indexedDB data with the new created record id, if we are online.
    const idbData: LivingAppsConsumptionRecord[] | null = await localforage.getItem(
      this.IDB_KEY_RECORDS
    );
    if (!res.data.fromCache) {
      if (idbData) {
        for (const recordData of idbData) {
          if (recordData.id === recordCacheId) {
            recordData.id = res.data.saveRecord.record.id;
          }
        }
      }
      const recs = await localforage.setItem(this.IDB_KEY_RECORDS, idbData);
    }
    this.context.commit("setRecordsData", idbData);
  }

  @Action
  public async deleteRecordsFromLivingApps(attrs: any) {
    const res = await client.mutate({
      context: {
        headers: {
          "x-graphql-req-name": "consumptions",
          "x-graphql-req-renew-cache": true
        }
      },
      fetchPolicy: "no-cache",
      mutation: deleteRecordMutation,
      variables: {
        authToken: StoreAuthentication.getGraphqlAuthToken,
        appId: APP_ID_GL_VERBRAUCH,
        recordIds: attrs.recordIds
      }
    });
  }
}
