









import { Component, Vue } from "vue-property-decorator";
import { StoreAssignments } from "@/store";

@Component
export default class ActiveAssignmentButton extends Vue {
  public get hasActiveAssi() {
    if (StoreAssignments.getScannedRecord) {
      return true;
    }
    return false;
  }

  public handleClick() {
    this.$router.push({ name: "active_assignment" });
  }
}
