













































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import store, { StoreClipboard } from "@/store";
import Validation from "@/mixins/Validation.vue";
import { LivingAppsAssignmentRecord } from "@/types";
import { LivingAppsClipboardRecord } from "@/clipboardTypes";
import ClipboardList from "@/components/ClipboardList.vue";
import ClipboardForms from "@/components/ClipboardForms.vue";

@Component({
  components: {
    ClipboardList,
    ClipboardForms,
  },
})
export default class Clipboard extends Mixins(Validation) {
  [index: string]: any;
  // Is true if we are in a report context.
  // If false the 'creation' aspect is present, otherwise the 'choose item' aspect.
  @Prop(Boolean) public insertFirst!: any;

  // 'assiRecord' only exists if the clipboard was called in an assignment context.
  @Prop(Object) public assiRecord!: LivingAppsAssignmentRecord;

  // 'reportRecord' only exists if the clipboard was called in an report context.
  @Prop(Object) public reportRecord!: LivingAppsAssignmentRecord;

  @Prop(String) public itemType!: string;

  @Prop(Boolean) public useRadio!: string;

  @Prop(String) public segmentStart!: string;

  @Prop(String) public fieldName!: string;

  @Prop(Function) public dismissModal: any;

  public clEditStatus: boolean = false;

  public trashClicked: boolean = false;

  public showForm: boolean = false;

  public formType: string = "";

  public insertFirstState: boolean = false;

  public records: LivingAppsClipboardRecord[] = [];

  public get showClipboard() {
    if (!this.insertFirstState) {
      this.insertFirstState = this.insertFirst;
    }
    return this.insertFirstState;
  }

  public set showClipboard(val: boolean) {
    this.insertFirstState = val;
  }

  public get clipboardEdit() {
    return this.clEditStatus;
  }

  public set clipboardEdit(val: boolean) {
    this.clEditStatus = val;
    if (!val && this.useRadio) {
      this.dismissModal();
    }
  }

  public setClipboard(val: boolean) {
    this.showClipboard = val;
    this.clipboardEdit = false;
  }

  public setFormType(type: string) {
    this.formType = type;
  }

  public handleTrash() {
    const cbl = this.$refs.clipboardList as any;
    cbl.trashAction();
  }

  public handleCancel() {
    if (this.showForm) {
      this.formType = "";
      this.showForm = false;
    } else {
      this.dismissModal();
    }
  }

  @Watch("formType")
  public prepareForm(value: string, olValue: string) {
    if (value) {
      this.showForm = true;
      return;
    }
    this.showForm = false;
  }

  protected mounted() {
    if (this.useRadio) {
      this.clipboardEdit = true;
    }
  }
}
