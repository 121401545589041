






























import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store, { StoreEmployees, StoreAuthentication } from "../store";
import { LivingAppsEmployeeRecord, LivingAppsEmployeeControl } from "../types";
import { EventBus } from "@/main";
import EmployeesList from "@/components/EmployeesList.vue";
import ActiveAssignmentButton from "@/components/ActiveAssignmentButton.vue";
import AssignmentDetailNav from "@/components/AssignmentDetailNav.vue";

@Component({
  components: {
    EmployeesList,
    ActiveAssignmentButton,
    AssignmentDetailNav
  }
})
export default class Employees extends Vue {
  @Prop({ type: Boolean, default: true }) public userInteraction!: boolean;
  @Prop(Function) public dismissModal: any;

  public storeEmployees = StoreEmployees;
  public controls?: LivingAppsEmployeeControl | null;
  public edit: boolean = false;
  public type: string = "maintenance";
  public loadingController?: HTMLIonLoadingElement | null;

  public get detailNavRoutes() {
    const id: string = this.$route.params.id;
    const type: string = this.type;
    return {
      infos: { name: `${this.type}_detail`, params: { id, type } },
      reports: { name: "reports", params: { id, type } },
      consumptions: { name: "consumptions", params: { id, type } },
      employees: { name: "employees", params: { id, type } },
      edit: { name: "", params: { id, type } }
    };
  }

  public get getUserInteraction(): boolean {
    return this.userInteraction;
  }

  public get records(): LivingAppsEmployeeRecord[] {
    return this.storeEmployees.getRecords;
  }

  public get assignmentId(): string {
    if (this.$route.params && this.$route.params.id) {
      return this.$route.params.id;
    }
    return "";
  }

  public get costcentre(): string {
    return StoreAuthentication.getCostcentre;
  }

  public get costcentreOperator(): LivingAppsEmployeeRecord | null {
    return StoreAuthentication.getCostcentreOperator;
  }

  public getRecordById(recordId: string) {
    for (const record of this.storeEmployees.getRecords) {
      if (record.id === recordId) {
        return record;
      }
    }
    return null;
  }

  public handleClick(route: string) {
    this.$router.push(route);
  }

  public handleControls() {
    this.controls = this.storeEmployees.getControls;
  }

  public closeModal() {
    this.dismissModal();
  }

  protected mounted() {
    this.handleControls();
    EventBus.$off("dismissEmployeeModal").$on(
      "dismissEmployeeModal",
      this.closeModal
    );
  }
}
