



































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { LivingAppsFileControl } from "../types";
import { HOST_URL } from "../store";

@Component
export default class AudioRecordingInput extends Vue {
  @Prop() public name!: string;
  @Prop() public id!: string;
  @Prop() public file!: File | LivingAppsFileControl | object | null;
  @Prop() public asPlayer!: boolean;
  @Prop() public asMicroPlayer!: boolean;

  public Recorder: MediaRecorder | null = null;
  public hour: number = 0;
  public min: number = 0;
  public sec: number = 0;
  public buttonStr: string = "Aufnahme starten";
  public buttonColor: string = "primary";
  public timeStr: string = "00:00:00";
  public currentRecordUrl?: string = "";
  public audioChunks: Blob[] = [];
  protected started: boolean = false;
  protected timerIntervalId: number | null = null;

  public startStopRecording() {
    navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((stream) => {
      if (!this.Recorder) {
        this.Recorder = new MediaRecorder(stream, {
          mimeType: "audio/webm; codecs=opus",
          bitsPerSecond: 128000,
        });
      }
      if (this.started) {
        this.Recorder.stop();
        this.buttonStr = "Aufnahme starten";
        this.buttonColor = "primary";
        const time = new Date().getTime();
        const filename = `Aufnahme-${time}.webm`;
        const blob = new Blob(this.audioChunks, { type: "audio/webm; codecs=opus" });
        this.currentRecordUrl = URL.createObjectURL(blob);
        const file = new File(this.audioChunks, filename, {
          type: "audio/webm; codecs=opus",
          lastModified: time,
        });
        this.$emit("recordCompleted", file);
        this.audioChunks = [];
        this.started = false;
        this.sec = 0;
        this.min = 0;
        this.hour = 0;
        if (this.timerIntervalId) {
          window.clearInterval(this.timerIntervalId);
          this.timerIntervalId = null;
        }
      } else {
        this.$emit("recordStarted");
        this.buttonStr = "Aufnahme stoppen";
        this.buttonColor = "danger";
        this.currentRecordUrl = "";
        this.audioChunks = [];
        if (this.Recorder) {
          this.Recorder.ondataavailable = (e: BlobEvent) => {
            this.audioChunks.push(e.data);
          };
        }
        this.Recorder.start(1000);
        this.started = true;
        if (!this.timerIntervalId) {
          this.timerIntervalId = window.setInterval(() => {
            this.sec++;
          }, 1000);
        }
      }
    });
  }

  public deleteAudio() {
    this.currentRecordUrl = "";
    this.$emit("recordDeleted");
  }

  public play() {
    const audioElm: HTMLAudioElement | null = document.querySelector(`#_${this.id}`);
    if (audioElm) {
      audioElm.play();
    }
  }

  public stop() {
    const audioElm: HTMLAudioElement | null = document.querySelector(`#_${this.id}`);
    if (audioElm) {
      audioElm.pause();
      audioElm.currentTime = 0;
    }
  }

  @Watch("sec")
  public updateTimeString(val: number, valOld: number) {
    const secFiller = this.sec < 10 ? "0" : "";
    const minFiller = this.min < 10 ? "0" : "";
    const hourFiller = this.hour < 10 ? "0" : "";
    if (this.sec === 60) {
      this.sec = 0;
      this.min++;
    }
    if (this.min === 60) {
      this.min = 0;
      this.hour++;
    }
    this.timeStr = `${hourFiller}${this.hour}:${minFiller}${this.min}:${secFiller}${this.sec}`;
  }

  @Watch("file", { immediate: true })
  public updateRecordUrl(
    file: File | LivingAppsFileControl | null,
    fileOld: File | LivingAppsFileControl | null
  ) {
    if (file) {
      if (file instanceof File) {
        this.currentRecordUrl = URL.createObjectURL(file);
      } else {
        this.currentRecordUrl = HOST_URL + file.url;
      }
    }
  }
}
