import gql from "graphql-tag";
export const saveReportsRecordMutation = gql`
  mutation SaveRecord(
    $authToken: String
    $appId: String!
    $id: String
    $template: String
    $detail: Boolean
    $auftrag_id: String
    $offline_id: String
    $titel: String
    $beschreibung: String
    $mitarbeiter: String
    $anl_id: String
    $upload2: InputFieldFile
    $audio: InputFieldFile
    $angelegt_am: String
    $angelegt_von: String
  ) {
    saveRecord(
      authToken: $authToken
      id: $appId
      template: $template
      detail: $detail
      input: {
        id: $id
        fields: [
          { identifier: "auftrag_id", value: $auftrag_id }
          { identifier: "offline_id", value: $offline_id }
          { identifier: "titel", value: $titel }
          { identifier: "beschreibung", value: $beschreibung }
          { identifier: "mitarbeiter", value: $mitarbeiter }
          { identifier: "anl_id", value: $anl_id }
          { identifier: "angelegt_von", value: $angelegt_von }
        ]
        fileFields: [
          { identifier: "upload2", value: $upload2 }
          { identifier: "audio", value: $audio }
        ]
        dateFields: [
            { identifier: "angelegt_am", value: $angelegt_am }
        ]
        lookupFields: [{ identifier: "typ", items: [{ key: "bericht", label: "Bericht" }] }]
      }
    ) {
      record {
        id
        auftrag_id: value(identifier: "auftrag_id") {
          ... on ValueTypeString {
            value
          }
        }
        offline_id: value(identifier: "offline_id") {
          ... on ValueTypeString {
            value
          }
        }
        titel: value(identifier: "titel") {
          ... on ValueTypeString {
            value
          }
        }
        typ: value(identifier: "typ") {
          ... on ValueTypeLookUpItem {
            value {
              key
              label
            }
          }
        }
        mitarbeiter: value(identifier: "mitarbeiter") {
          ... on ValueTypeString {
            value
          }
        }
        anl_id: value(identifier: "anl_id") {
          ... on ValueTypeString {
            value
          }
        }
        nummer: value(identifier: "nummer") {
          ... on ValueTypeString {
            value
          }
        }
        beschreibung: value(identifier: "beschreibung") {
          ... on ValueTypeString {
            value
          }
        }
        upload2: value(identifier: "upload2") {
          ... on ValueTypeFile {
            value {
              id
              url
              filename
              mimetype
              width
              height
              createdat
            }
          }
        }
        upload_typ: value(identifier: "upload_typ") {
          ... on ValueTypeString {
            value
          }
        }
        audio: value(identifier: "audio") {
          ... on ValueTypeFile {
            value {
              id
              url
              filename
              mimetype
              createdat
            }
          }
        }
        angelegt_am: value(identifier: "angelegt_am") {
          ... on ValueTypeString {
            value
          }
        }
        prioritaet: value(identifier: "prioritaet") {
          ... on ValueTypeString {
            value
          }
        }
        angelegt_von: value(identifier: "angelegt_von") {
          ... on ValueTypeString {
            value
          }
        }
      }
    }
  }
`;
