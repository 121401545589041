


























































import { Component, Vue, Prop } from "vue-property-decorator";
import { Routes } from "@/types";
import { StoreAuthentication } from "@/store";

@Component
export default class AssignmentDetailNav extends Vue {
  @Prop() public routes!: Routes;
  @Prop({ type: Boolean, default: true }) public editable?: boolean;

  public get isEditable() {
    return this.editable;
  }

  public get costcentre() {
    return StoreAuthentication.getCostcentre;
  }

  public handleClick(name: string) {
    if (this.routes[name].name !== this.$router.currentRoute.name) {
      this.$router.push(this.routes[name]);
    }
  }

  public handleEdit() {
    const listeners: any = this.$listeners;
    if (listeners.hasOwnProperty("new-record")) {
      if (this.$route.params && this.$route.params.type) {
        this.$emit("new-record");
      } else {
        (this.$parent as any).newRecord();
      }
    } else {
      if (this.$route.params && this.$route.params.type) {
        this.$emit("edit-record");
      } else {
        (this.$parent as any).editRecord();
      }
    }
  }

  public displayIfListening(listener: string) {
    const listeners: any = this.$listeners;
    if (listeners.hasOwnProperty(listener)) {
      return true;
    }
    return false;
  }

  private getHereColor(name: string): string {
    for (const key in this.routes) {
      if (this.routes.hasOwnProperty(key) && key === name) {
        const route = this.routes[key];
        if (this.$router.currentRoute.name === route.name) {
          return "primary";
        }
      }
    }
    return "";
  }
}
