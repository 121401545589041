
import { Component, Vue } from "vue-property-decorator";
import { LivingAppsAssignmentRecord } from "@/types";

@Component
export default class Utils extends Vue {
  public getISOWithTimezoneString(date?: Date): string {
    const d: Date = date || new Date();
    const dateStr = new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().split(".")[0];
    return dateStr;
  }

  public getDetailRoute(record: LivingAppsAssignmentRecord): string {
    if (record && record.typ) {
      if (record.typ.value[0].key === "wartung") {
        return "maintenance_detail";
      }
      return "repairs_detail";
    }
    return "main";
  }
}
