import { LivingAppsEmployeeRecord, LivingAppsEmployeeControl } from "@/types";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { client } from "@/utils/client";
import store, { APP_ID_GL_MITARBEITER, StoreAuthentication } from "@/store";
import localforage from "localforage";
import uniqid from "uniqid";
import { getEmployeeRecordsQuery } from "@/store/graphql/GetEmployeeRecords.graphql";
import { deleteRecordMutation } from "@/store/graphql/DeleteRecords.graphql";
import { saveRecordMutation } from "@/store/graphql/SaveRecord.graphql";

@Module({ store, namespaced: true, name: "storeEmployees" })
export default class Employees extends VuexModule {
  public records: LivingAppsEmployeeRecord[] = [];
  public controls: LivingAppsEmployeeControl | null = null;
  public _IDB_KEY_RECORDS: string = "employees";
  public _IDB_KEY_CONTROLS: string = "employees_controls";

  public get IDB_KEY_RECORDS(): string {
    return this._IDB_KEY_RECORDS;
  }

  public get IDB_KEY_CONTROLS(): string {
    return this._IDB_KEY_CONTROLS;
  }

  public get recordsLength(): number {
    return this.records.length;
  }

  public get getRecords(): LivingAppsEmployeeRecord[] {
    return this.records;
  }

  public get getMachineOperatorSymbols(): string[] {
    const records = this.getRecords;
    const symbolList: string[] = [];
    for (const record of records) {
      if (record.maschinenfuehrer && record.maschinenfuehrer.value) {
        if (record.kuerzel && record.kuerzel.value) {
          symbolList.push((record.kuerzel.value as string).toLowerCase());
        }
      }
    }
    return symbolList;
  }

  public get getRecordByEmail(): any {
    return (email: string): LivingAppsEmployeeRecord | null => {
      for (const record of this.records) {
        if (record.e_mail && record.e_mail.value === email) {
          return record;
        }
      }
      return null;
    };
  }

  public get getControls(): LivingAppsEmployeeControl | null {
    return this.controls;
  }

  @Mutation
  public setRecordsData(records: LivingAppsEmployeeRecord[]) {
    this.records = records;
  }

  @Mutation
  public setControlsData(controls: LivingAppsEmployeeControl | null) {
    this.controls = controls;
  }

  @Action
  public async getDataFromEndpoint(attrs: any) {
    const res = await client.query({
      context: {
        headers: {
          "x-graphql-req-name": "employees",
          "x-graphql-req-renew-cache": true
        }
      },
      fetchPolicy: "no-cache",
      query: getEmployeeRecordsQuery,
      variables: {
        authToken: StoreAuthentication.getGraphqlAuthToken,
        appId: APP_ID_GL_MITARBEITER
      }
    });
    const recValue = res.data.livingapi.viewtemplateData.app.records || [];
    const appData = res.data.livingapi.viewtemplateData.app;
    let realRecords: LivingAppsEmployeeRecord[];

    if (res.data.fromCache) {
      realRecords = recValue;
    } else {
      const localRecords: LivingAppsEmployeeRecord[] | null = await localforage.getItem(
        this.IDB_KEY_RECORDS
      );
      const offlineRecords: LivingAppsEmployeeRecord[] = [];
      if (localRecords) {
        for (const record of localRecords) {
          if (record.id && record.id.includes("__cached__")) {
            offlineRecords.push(record);
          }
        }
      }
      realRecords = [...offlineRecords, ...recValue];
      await localforage.setItem(this.IDB_KEY_RECORDS, realRecords);
    }
    this.context.commit("setRecordsData", realRecords);
    const controls: LivingAppsEmployeeControl = {} as LivingAppsEmployeeControl;
    for (const key in appData) {
      if (key !== "records") {
        if (appData.hasOwnProperty(key)) {
          controls[key] = appData[key];
        }
      }
    }
    const ctrlValue = controls;
    if (!res.data.fromCache) {
      await localforage.setItem(this.IDB_KEY_CONTROLS, ctrlValue);
    }
    this.context.commit("setControlsData", ctrlValue);
  }

  @Action
  public async updateRecordData(attrs: any) {
    // Update indexedDB record data with form data.
    const idbData: any = await localforage.getItem(this.IDB_KEY_RECORDS);
    let record: LivingAppsEmployeeRecord;
    for (record of idbData) {
      if (record.id === attrs.data.id) {
        for (const key in attrs.data) {
          if (attrs.data.hasOwnProperty(key)) {
            const d = attrs.data[key];
            record[key] = d;
          }
        }
        // Now we store the altered record data back into indexedDB.
        const recs = await localforage.setItem(this.IDB_KEY_RECORDS, idbData);
        this.context.commit("setRecordsData", recs);
        attrs.app.$emit("saved");
        // Try to store the data to livingapps app if data is not new created on the device.
        if (!attrs.data.id.includes("__cached__")) {
          this.storeRecordToLivingApps({
            record,
            eventBus: attrs.eventBus,
            app: attrs.app
          });
        }
        break;
      }
    }
  }

  @Action
  public async createRecordData(attrs: any) {
    // Update indexedDB record data with form data.
    const newRecord: LivingAppsEmployeeRecord = attrs.data;
    newRecord.id = uniqid("__cached__") as string;
    const idbData: any = await localforage.getItem(this.IDB_KEY_RECORDS);
    idbData.unshift(newRecord);
    const recs = await localforage.setItem(this.IDB_KEY_RECORDS, idbData);

    this.context.commit("setRecordsData", recs);
    attrs.app.$emit("saved");

    // Now we store the altered record data back into indexedDB.
    // Try to store the data to livingapps app.
    // this.storeRecordToLivingApps({
    //   record: newRecord,
    //   eventBus: attrs.eventBus,
    //   app: attrs.app
    // });
  }

  @Action
  public async deleteRecordData(attrs: any) {
    const recordIds = attrs.recordIds;
    const idbData: LivingAppsEmployeeRecord[] | null = await localforage.getItem(this.IDB_KEY_RECORDS);
    const realData = [];
    if (idbData) {
      for (const record of idbData) {
        if (!recordIds.includes(record.id)) {
          realData.push(record);
        }
      }
      await localforage.setItem(this.IDB_KEY_RECORDS, realData);
      this.context.commit("setRecordsData", realData);
      this.deleteRecordsFromLivingApps({
        recordIds,
        app: attrs.app,
        eventBus: attrs.eventBus
      });
    }
  }

  @Action
  public async storeRecordToLivingApps(attrs: any) {
    const record = { ...attrs.record };
    let recordCacheId: string | null = null;
    if (record.id.includes("__cached__")) {
      recordCacheId = record.id;
      record.id = null;
    }

    // Sending mutation to the grapql server.
    const variables: any = {
      authToken: StoreAuthentication.getGraphqlAuthToken,
      appId: APP_ID_GL_MITARBEITER,
      id: record.id
    };
    Object.keys(record).map((key: string) => {
      if (key !== "id") {
        if (record[key]) {
          if (record[key].value instanceof File) {
            variables[key] = { file: record[key].value };
          } else {
            variables[key] = record[key].value;
          }
        } else {
          if (key === "bild") {
            variables[key] = { file: null };
          } else {
            variables[key] = null;
          }
        }
      }
    });
    const res = await client.mutate({
      context: {
        headers: {
          "x-graphql-req-name": "employees",
          ...(() => {
            return recordCacheId
              ? { "x-skip-bgsync": true, "x-graphql-req-renew-cache": false }
              : { "x-graphql-req-renew-cache": true };
          })()
        }
      },
      fetchPolicy: "no-cache",
      mutation: saveRecordMutation,
      variables
    });

    // Updating indexedDB data with the new created record id, if we are online.
    const idbData: LivingAppsEmployeeRecord[] | null = await localforage.getItem(
      this.IDB_KEY_RECORDS
    );
    if (!res.data.fromCache) {
      if (idbData) {
        for (const recordData of idbData) {
          if (recordData.id === recordCacheId) {
            recordData.id = res.data.saveRecord.record.id;
          }
        }
      }
      const recs = await localforage.setItem(this.IDB_KEY_RECORDS, idbData);
    }
    this.context.commit("setRecordsData", idbData);
  }

  @Action
  public async deleteRecordsFromLivingApps(attrs: any) {
    const res = await client.mutate({
      context: {
        headers: {
          "x-graphql-req-name": "employees",
          "x-graphql-req-renew-cache": true
        }
      },
      fetchPolicy: "no-cache",
      mutation: deleteRecordMutation,
      variables: {
        authToken: StoreAuthentication.getGraphqlAuthToken,
        appId: APP_ID_GL_MITARBEITER,
        recordIds: attrs.recordIds
      }
    });
  }
}
