
import { Component, Vue } from "vue-property-decorator";
import { ClipboardSortedTypes, LivingAppsClipboardRecord } from "@/clipboardTypes";
import { StoreClipboard } from "@/store";
import { LivingAppsAssignmentRecord, LivingAppsFileControl, LivingAppsRecordValue } from "@/types";
import { HOST_URL } from "@/store/index";

@Component
export default class ClipboardUtils extends Vue {
  public hasClipboardItemsForAssi(recordId: string, itemType?: string): boolean {
    const records: LivingAppsClipboardRecord[] = StoreClipboard.getRecords();
    for (const record of records) {
      if (record.record_id?.value === recordId) {
        if (itemType) {
          if (itemType === "note" && !record.bild && !record.sprachnachricht) {
            return true;
          } else if (itemType === "image" && record.bild) {
            return true;
          } else if (itemType === "audio" && record.sprachnachricht) {
            return true;
          }
        } else {
          return true;
        }
      }
    }
    return false;
  }

  public clipboardItemsForAssi(recordId: string): LivingAppsClipboardRecord[] {
    const records: LivingAppsClipboardRecord[] = StoreClipboard.getRecords();
    const clipItems: LivingAppsClipboardRecord[] = [];
    for (const record of records) {
      if (record.record_id?.value === recordId) {
        clipItems.push(record);
      }
    }
    return clipItems;
  }

  public async clipboardItemAsFile(record: LivingAppsClipboardRecord): Promise<File | null> {
    if (record.bild || record.sprachnachricht) {
      let fileObj: LivingAppsFileControl | null = null;
      if (record.bild) {
        fileObj = record.bild.value as LivingAppsFileControl;
      } else if (record.sprachnachricht) {
        fileObj = record.sprachnachricht.value as LivingAppsFileControl;
      }
      if (fileObj instanceof File) {
        return fileObj;
      } else if (fileObj) {
        const path = window.location.host.includes("localhost")
          ? `${HOST_URL}${fileObj.url}`
          : (fileObj.url as string);
        const req: Request = new Request(path);
        const response: Response = await fetch(req);
        if (response.ok) {
          const b = await response.blob();
          const opts: FilePropertyBag = {
            lastModified: Date.now(),
            type: fileObj.mimetype,
          };
          const f = new File([b], fileObj.filename as string, opts);
          return f;
        }
      }
    }
    return null;
  }

  public sortSelectedItemsByType(selectedItems: LivingAppsClipboardRecord[]): ClipboardSortedTypes {
    const sortedItems: ClipboardSortedTypes = { note: [], image: [], audio: [] };
    for (const item of selectedItems) {
      if (item.bild) {
        sortedItems.image.push(item);
      } else if (item.sprachnachricht) {
        sortedItems.audio.push(item);
      } else {
        sortedItems.note.push(item);
      }
    }
    return sortedItems;
  }

  public handleClipboardCleanup() {
    StoreClipboard.cleanup();
  }
}
