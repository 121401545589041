








import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class NoListEntriesMessage extends Vue {
  @Prop({ default: "Keine Einträge vorhanden!" }) public message!: string;
}
