import { LivingAppsCostcentreRecord } from "@/types";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { client } from "@/utils/client";
import store, { APP_ID_GL_KOSTENSTELLE, StoreAssignments, StoreAuthentication } from "@/store";
import localforage from "localforage";
import { getCostcentreRecordsQuery } from "@/store/graphql/GetCostcentreRecords.graphql";

@Module({ store, namespaced: true, name: "storeCostcentres" })
export default class Costcentres extends VuexModule {
  public records: LivingAppsCostcentreRecord[] = [];
  public _IDB_KEY_RECORDS: string = "costcentres";

  public get IDB_KEY_RECORDS(): string {
    return this._IDB_KEY_RECORDS;
  }

  public get getRecords(): LivingAppsCostcentreRecord[] {
    return this.records;
  }

  public get infoForCostcentre(): (costcentre: string) => string {
    return (costcentre: string): string => {
      for (const record of this.getRecords) {
        if (record.kostenstelle && record.kostenstelle.value === costcentre) {
          if (record.beschreibung) {
            return record.beschreibung.value as string;
          }
          return "";
        }
      }
      return "";
    };
  }

  public get codeForCostcentre(): (costcentre: string) => string {
    return (costcentre: string): string => {
      for (const record of this.getRecords) {
        if (record.kostenstelle && record.kostenstelle.value === costcentre) {
          if (record.code) {
            return record.code.value as string;
          }
          return "";
        }
      }
      return "";
    };
  }

  public get descForCostcentre(): (costcentre: string) => string {
    return (costcentre: string): string => {
      for (const record of this.getRecords) {
        if (record.kostenstelle && record.kostenstelle.value === costcentre) {
          if (record.beschreibung) {
            return record.beschreibung.value as string;
          }
          return "";
        }
      }
      return "";
    };
  }

  public get costcentreForCode(): (code: string) => string {
    return (code: string): string => {
      for (const record of this.getRecords) {
        if (record.code && record.code.value === code) {
          if (record.kostenstelle) {
            return record.kostenstelle.value as string;
          }
          return "";
        }
      }
      return "";
    };
  }

  public get costcentreRecordId(): (costcentre: string) => string {
    return (costcentre: string): string => {
      for (const record of this.getRecords) {
        if (record.kostenstelle && record.kostenstelle.value === costcentre) {
          return record.id as string;
        }
      }
      return "";
    };
  }

  @Mutation
  public setRecordsData(records: LivingAppsCostcentreRecord[]) {
    this.records = records;
  }

  @Action
  public async getDataFromEndpoint(attrs?: any) {
    const res = await client.query({
      context: {
        headers: {
          "x-graphql-req-name": "costcentres",
          "x-graphql-req-renew-cache": true
        }
      },
      fetchPolicy: "no-cache",
      query: getCostcentreRecordsQuery,
      variables: {
        authToken: StoreAuthentication.getGraphqlAuthToken,
        appId: APP_ID_GL_KOSTENSTELLE
      }
    });
    const recValue = res.data.livingapi.viewtemplateData.app.records || [];
    const appData = res.data.livingapi.viewtemplateData.app;
    let realRecords: LivingAppsCostcentreRecord[];

    if (res.data.fromCache) {
      realRecords = recValue;
    } else {
      const localRecords: LivingAppsCostcentreRecord[] | null = await localforage.getItem(
        this.IDB_KEY_RECORDS
      );
      const offlineRecords: LivingAppsCostcentreRecord[] = [];
      if (localRecords) {
        for (const record of localRecords) {
          if (record.id && record.id.includes("__cached__")) {
            offlineRecords.push(record);
          }
        }
      }
      realRecords = [...offlineRecords, ...recValue];
      await localforage.setItem(this.IDB_KEY_RECORDS, realRecords);
    }
    this.context.commit("setRecordsData", realRecords);
  }
}
