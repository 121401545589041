import Vue from "vue";
import {
  LivingAppsAssignmentRecord,
  LivingAppsAssignmentControl,
  LivingAppsEmployeeRecord,
  LivingAppsConsumptionRecord,
  LivingAppsRecordValue,
  LivingAppsLookupItem,
  AssignmentAdviceData
} from "@/types";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { client } from "@/utils/client";
import store, {
  APP_ID_GL_AUFTRAEGE,
  StoreAuthentication,
  StoreEmployees,
  StoreConsumptions,
  StoreAssignmentsStatus,
  StoreAssignmentsAdviceStatus
} from "@/store";
import localforage from "localforage";
import uniqid from "uniqid";
import { getAssignmentRecordsQuery } from "@/store/graphql/GetAssignmentRecords.graphql";
import { deleteRecordMutation } from "@/store/graphql/DeleteRecords.graphql";

@Module({ store, namespaced: true, name: "storeAssignments" })
export default class Assignments extends VuexModule {
  public records: LivingAppsAssignmentRecord[] = [];
  public scannedRecord: LivingAppsAssignmentRecord | null = null;
  public controls: LivingAppsAssignmentControl | null = null;
  // public username: string = this.storeAuthentication.getUsername;

  protected _IDB_KEY_RECORDS: string = "assignments";
  protected _IDB_KEY_CONTROLS: string = "assignments_controls";

  public get IDB_KEY_RECORDS(): string {
    return this._IDB_KEY_RECORDS;
  }

  public get IDB_KEY_CONTROLS(): string {
    return this._IDB_KEY_CONTROLS;
  }

  public get recordsLength(): number {
    return this.records.length;
  }

  public get getRecords(): LivingAppsAssignmentRecord[] {
    return this.records;
  }

  public get getRecordById(): any {
    return (recordId: string): LivingAppsAssignmentRecord | null => {
      for (const record of this.getRecords) {
        if (record.id === recordId) {
          return record;
        }
      }
      return null;
    };
  }

  public get getScannedRecord(): LivingAppsAssignmentRecord | null {
    return this.scannedRecord;
  }

  public get getReports(): any {
    return (assignmentId: string): LivingAppsAssignmentRecord[] | null => {
      const records = [];
      for (const record of this.records) {
        if (record.auftrag_id) {
          const reportId = record.auftrag_id.value;
          if (reportId === assignmentId && record.typ && record.typ.value[0].key === "bericht") {
            records.push(record);
          }
        }
      }
      return records;
    };
  }

  public get getAdhoc(): any {
    return (currEmployeeSymbol: string): LivingAppsAssignmentRecord | null => {
      const records = this.context.getters.getRecords as LivingAppsAssignmentRecord[];
      for (const record of records) {
        const employeeSymbol = record.mitarbeiter
          ? (record.mitarbeiter.value as string).toLowerCase()
          : "";
        if (!record.typ && !record.anl_id && employeeSymbol === currEmployeeSymbol) {
          return record;
        }
      }
      return null;
    };
  }

  public get getControls(): LivingAppsAssignmentControl | null {
    return this.controls;
  }

  public get getAssignmentStatus(): any {
    return (assignmentId: string, employeeId: string): string => {
      const scannedRecord = this.getScannedRecord;
      const consumption: LivingAppsConsumptionRecord | null = StoreConsumptions.getOpenTimeConsumptionForAssignment(
        assignmentId,
        employeeId
      );
      if (
        consumption &&
        scannedRecord &&
        consumption.auftrag_id &&
        scannedRecord.auftrag_id &&
        consumption.auftrag_id.value === scannedRecord.auftrag_id.value
      ) {
        return "in_arbeit";
      }
      return "neu";
    };
  }

  public get filterForSorting(): (
    records: LivingAppsAssignmentRecord[]
  ) => [LivingAppsAssignmentRecord[], LivingAppsAssignmentRecord[], LivingAppsAssignmentRecord[]] {
    return (
      records: LivingAppsAssignmentRecord[]
    ): [
        LivingAppsAssignmentRecord[],
        LivingAppsAssignmentRecord[],
        LivingAppsAssignmentRecord[]
      ] => {
      const eRecords: LivingAppsAssignmentRecord[] = [];
      const pRecords: LivingAppsAssignmentRecord[] = [];
      const otherRecords: LivingAppsAssignmentRecord[] = [];
      for (const record of records) {
        if (record.faellig_bis) {
          eRecords.push(record);
        } else if (record.prioritaet && !record.faellig_bis) {
          pRecords.push(record);
        } else {
          otherRecords.push(record);
        }
      }
      return [eRecords, pRecords, otherRecords];
    };
  }

  public get userListFromString(): (userString: string) => string[] {
    return (userString) => {
      const userList = userString.replace(/\s/g, "").toLowerCase().split(",");
      return userList;
    };
  }

  @Mutation
  public setRecordsData(records: LivingAppsAssignmentRecord[]) {
    this.records = records;
  }

  @Mutation
  public setScannedRecordData(record: LivingAppsAssignmentRecord | null) {
    this.scannedRecord = record;
  }

  @Mutation
  public setControlsData(controls: LivingAppsAssignmentControl | null) {
    this.controls = controls;
  }

  @Action
  public async getDataFromEndpoint(attrs: any) {
    const recValues: LivingAppsAssignmentRecord[] = [];
    let employeeId: string | null = null;
    let assignmentIds: string[] = [];
    const res = await client.query({
      context: {
        headers: {
          "x-graphql-req-name": "assignments",
          "x-graphql-req-renew-cache": true
        }
      },
      fetchPolicy: "no-cache",
      query: getAssignmentRecordsQuery,
      variables: {
        authToken: StoreAuthentication.getGraphqlAuthToken,
        appId: APP_ID_GL_AUFTRAEGE
      }
    });
    if (StoreAuthentication.costcentre) {
      const costcentre = StoreAuthentication.costcentre;
      const employees = StoreEmployees.getRecords;
      const employeeSymbols: string[] = [];
      const adviceRecords: AssignmentAdviceData[] | null = await localforage.getItem(StoreAssignmentsAdviceStatus.IDB_KEY_RECORDS);
      const existingRecords: string[] = [];
      if (adviceRecords) {
        for (const advRecord of adviceRecords) {
          if (advRecord.record.id) {
            existingRecords.push(advRecord.record.id);
          }
        }
      }
      for (const empl of employees) {
        if (empl.kuerzel && empl.maschinenfuehrer) {
          employeeSymbols.push(empl.kuerzel.value as string);
        }
      }
      for (const record of res.data.livingapi.viewtemplateData.app
        .records as LivingAppsAssignmentRecord[]) {
        if (
          record.maschinenfuehrer &&
          (
            (record.anl_id && record.anl_id.value === costcentre) ||
            (record.mitarbeiter && employeeSymbols.includes(record.mitarbeiter.value as string))
          )
        ) {
          recValues.push(record);
        } else if (record.id && existingRecords.includes(record.id)) {
          recValues.push(record);
        }
      }
    } else {
      const employeeSymbol = attrs.employeeSymbol || null;
      const employee: LivingAppsEmployeeRecord | null = StoreEmployees.getRecordByEmail(
        StoreAuthentication.getUsername
      ) as LivingAppsEmployeeRecord;
      employeeId = employee.ma_id ? (employee.ma_id.value as string) : null;
      assignmentIds = StoreConsumptions.getAssignmentIdsByEmployeeId(employeeId);
      for (const record of res.data.livingapi.viewtemplateData.app
        .records as LivingAppsAssignmentRecord[]) {
        const assignmentId: string = record.auftrag_id ? (record.auftrag_id.value as string) : "";
        const machineId: LivingAppsRecordValue | null | undefined = record.anl_id;
        const symbol: string = record.mitarbeiter ? (record.mitarbeiter.value as string) : "";
        if (
          assignmentIds.includes(assignmentId) ||
          (!machineId && symbol.toLowerCase() === employeeSymbol)
        ) {
          recValues.push(record);
        }
      }
    }
    const appData = res.data.livingapi.viewtemplateData.app;
    let realRecords: LivingAppsAssignmentRecord[];
    // Iterate all assignments from store, find type "bericht" and set a offline id.
    for (const storeRecord of recValues) {
      if (storeRecord.typ && storeRecord.typ.value[0].key === "bericht") {
        storeRecord.offline_id = {
          value: uniqid("__cached__") as string
        };
      }
    }
    if (res.data.fromCache) {
      realRecords = recValues;
    } else {
      const localRecords: LivingAppsAssignmentRecord[] | null = await localforage.getItem(
        this.IDB_KEY_RECORDS
      );
      const offlineRecords: LivingAppsAssignmentRecord[] = [];
      if (localRecords) {
        for (const record of localRecords) {
          if (record.id && record.id.includes("__cached__")) {
            offlineRecords.push(record);
          }
        }
      }
      const filteredRecords = this.filterForSorting(recValues);
      const expireRecords = filteredRecords[0];
      const prioRecords = filteredRecords[1];
      const otherRecords = filteredRecords[2];
      expireRecords.sort((a, b) => {
        if (a.faellig_bis && b.faellig_bis) {
          const aTime: number = new Date(`${a.faellig_bis.value} 00:00:00`).getTime();
          const bTime: number = new Date(`${b.faellig_bis.value} 00:00:00`).getTime();
          return (
            (((aTime > bTime) as unknown) as number) - (((aTime < bTime) as unknown) as number)
          );
        }
        return 0;
      });
      prioRecords.sort((a, b) => {
        if (a.prioritaet && b.prioritaet) {
          const aPrioVal = (a.prioritaet.value as LivingAppsLookupItem[])[0].label;
          const bPrioVal = (b.prioritaet.value as LivingAppsLookupItem[])[0].label;
          const aPrio = parseInt(aPrioVal, 10);
          const bPrio = parseInt(bPrioVal, 10);
          const sortResult =
            (((aPrio > bPrio) as unknown) as number) - (((aPrio < bPrio) as unknown) as number);
          if (sortResult === 0 && a.angelegt_am && b.angelegt_am) {
            const aTime: number = new Date(`${a.angelegt_am.value} 00:00:00`).getTime();
            const bTime: number = new Date(`${b.angelegt_am.value} 00:00:00`).getTime();
            return (
              (((aTime > bTime) as unknown) as number) - (((aTime < bTime) as unknown) as number)
            );
          }
          return sortResult;
        }
        return 0;
      });
      otherRecords.sort((a, b) => {
        if (a.angelegt_am && b.angelegt_am) {
          const aTime: number = new Date(`${a.angelegt_am.value} 00:00:00`).getTime();
          const bTime: number = new Date(`${b.angelegt_am.value} 00:00:00`).getTime();
          return (
            (((aTime > bTime) as unknown) as number) - (((aTime < bTime) as unknown) as number)
          );
        }
        return 0;
      });
      realRecords = [...offlineRecords, ...expireRecords, ...prioRecords, ...otherRecords];
      await localforage.setItem(this.IDB_KEY_RECORDS, realRecords);
    }
    await this.updateAssignmentsStatus(realRecords);
    if (StoreAuthentication.getCostcentre) {
      await this.updateAssignmentsAdviceStatus(realRecords);
    }
    this.context.commit("setRecordsData", realRecords);
    if (employeeId) {
      this.setEmployeeActiveAssignment({
        assignmentIds,
        employeeId
      });
    } else {
      this.setCostcentreActiveAssignment();
    }

    const controls: LivingAppsAssignmentControl = {} as LivingAppsAssignmentControl;
    for (const key in appData) {
      if (key !== "records") {
        if (appData.hasOwnProperty(key)) {
          controls[key] = appData[key];
        }
      }
    }
    const ctrlValue = controls;
    if (!res.data.fromCache) {
      await localforage.setItem(this.IDB_KEY_CONTROLS, ctrlValue);
    }
    this.context.commit("setControlsData", ctrlValue);
  }

  @Action
  public setEmployeeActiveAssignment(attrs: any) {
    const records = this.context.getters.getRecords as LivingAppsAssignmentRecord[];
    if (records.length) {
      for (const assignmentId of attrs.assignmentIds) {
        const openConsumption = StoreConsumptions.getOpenTimeConsumptionForAssignment(
          assignmentId,
          attrs.employeeId
        ) as LivingAppsConsumptionRecord | null;
        if (openConsumption) {
          for (const record of records) {
            if (
              record.auftrag_id &&
              record.typ &&
              record.typ.value[0].key !== "bericht" &&
              openConsumption.auftrag_id &&
              openConsumption.auftrag_id.value === record.auftrag_id.value
            ) {
              this.context.commit("setScannedRecordData", record);
              return;
            }
          }
        }
      }
    }
    this.context.commit("setScannedRecordData", null);
  }

  @Action
  public setCostcentreActiveAssignment() {
    const records = this.context.getters.getRecords as LivingAppsAssignmentRecord[];
    for (const record of records) {
      const assignmentId = record.auftrag_id ? record.auftrag_id.value as string : "";
      const openConsumption = StoreConsumptions.getOpenTimeConsumptionForAssignment(assignmentId, "-1");
      if (openConsumption) {
        this.context.commit("setScannedRecordData", record);
        return;
      }
    }
    this.context.commit("setScannedRecordData", null);
  }

  @Action
  public async deleteScannedRecord() {
    this.context.commit("setScannedRecordData", null);
  }

  @Action
  public async updateRecordData(attrs: any) {
    // Update indexedDB record data with form data.
    const idbData: any = await localforage.getItem(this.IDB_KEY_RECORDS);
    const mutation = attrs.mutation;
    let record: LivingAppsAssignmentRecord;
    for (record of idbData) {
      if (record.id && !record.id.includes("__cached__") && attrs.data.id.includes("__cached__")) {
        if (record.offline_id && record.offline_id.value === attrs.data.offline_id.value) {
          attrs.data.id = record.id;
        }
      }
      if (record.id === attrs.data.id) {
        for (const key in attrs.data) {
          if (attrs.data.hasOwnProperty(key)) {
            const d = attrs.data[key];
            record[key] = d;
          }
        }
        // Now we store the altered record data back into indexedDB.
        const recs = await localforage.setItem(this.IDB_KEY_RECORDS, idbData);
        this.context.commit("setRecordsData", recs);
        attrs.app.$emit("saved");
        // Try to store the data to livingapps app.
        await this.storeRecordToLivingApps({
          mutation,
          record,
          eventBus: attrs.eventBus,
          app: attrs.app
        });
        break;
      }
    }
  }

  @Action
  public async createRecordData(attrs: any) {
    // Update indexedDB record data with form data.
    const record: LivingAppsAssignmentRecord = attrs.data;
    const mutation = attrs.mutation;
    record.id = uniqid("__cached__") as string;
    if (record.typ && record.typ.value[0].key === "bericht") {
      record.offline_id = { value: record.id };
    }
    const idbData: any = await localforage.getItem(this.IDB_KEY_RECORDS);
    idbData.unshift(record);
    const recs = await localforage.setItem(this.IDB_KEY_RECORDS, idbData);

    this.context.commit("setRecordsData", recs);
    attrs.app.$emit("saved");

    if (!attrs.data.auftrag_id.value.includes("__cached__")) {
      await this.storeRecordToLivingApps({
        mutation,
        record,
        eventBus: attrs.eventBus,
        app: attrs.app
      });
    }
  }

  @Action
  public async deleteRecordData(attrs: any) {
    const recordIds = attrs.recordIds;
    const idbData: LivingAppsAssignmentRecord[] | null = await localforage.getItem(this.IDB_KEY_RECORDS);
    const realData = [];
    const realRecordIds = [];
    if (idbData) {
      for (const record of idbData) {
        if (
          recordIds.includes(record.id) ||
          (record.offline_id && recordIds.includes(record.offline_id.value))
        ) {
          realRecordIds.push(record.id);
        } else {
          realData.push(record);
        }
      }
      await localforage.setItem(this.IDB_KEY_RECORDS, realData);
      this.context.commit("setRecordsData", realData);
      await this.deleteRecordsFromLivingApps({
        recordIds: realRecordIds,
        app: attrs.app,
        eventBus: attrs.eventBus
      });
    }
  }

  @Action
  public async storeRecordToLivingApps(attrs: any) {
    const record = { ...attrs.record };
    const mutation = attrs.mutation;
    let recordCacheId: string | null = null;
    let template: string = "mutation_update_mobile";
    let detail: boolean = true;
    if (record.id.includes("__cached__")) {
      recordCacheId = record.id;
      record.id = null;
      template = "mutation_insert_mobile";
      detail = false;
    }

    // Sending mutation to the grapql server.
    const variables: any = {
      authToken: StoreAuthentication.getGraphqlAuthToken,
      appId: APP_ID_GL_AUFTRAEGE,
      id: record.id,
      template,
      detail
    };
    Object.keys(record).map((key: string) => {
      if (key !== "id") {
        if (record[key]) {
          if (record[key].value instanceof File) {
            variables[key] = { file: record[key].value };
          } else {
            variables[key] = record[key].value;
          }
        } else {
          if (key === "upload2" || key === "audio") {
            variables[key] = { file: null };
          } else {
            variables[key] = null;
          }
        }
      }
    });
    const res = await client.mutate({
      context: {
        headers: {
          "x-graphql-req-name": "assignments",
          "x-skip-bgsync": false,
          "x-graphql-req-renew-cache": true,
          ...(() => {
            return recordCacheId ? { "x-data-cache-id": recordCacheId } : null;
          })()

          // ...(() => {
          //   return recordCacheId
          //     ? { "x-skip-bgsync": false, "x-graphql-req-renew-cache": true }
          //     : { "x-graphql-req-renew-cache": true };
          // })()
        }
      },
      fetchPolicy: "no-cache",
      mutation,
      variables
    });

    // Updating indexedDB data with the new created record id, if we are online.
    const idbData: LivingAppsAssignmentRecord[] | null = await localforage.getItem(
      this.IDB_KEY_RECORDS
    );
    if (!res.data.fromCache) {
      if (idbData) {
        for (const recordData of idbData) {
          if (recordData.id === recordCacheId) {
            recordData.id = res.data.saveRecord.record.id;
          }
        }
      }
      const recs = await localforage.setItem(this.IDB_KEY_RECORDS, idbData);
    }
    this.context.commit("setRecordsData", idbData);
  }

  @Action
  public async deleteRecordsFromLivingApps(attrs: any) {
    const res = await client.mutate({
      context: {
        headers: {
          "x-graphql-req-name": "assignments",
          "x-graphql-req-renew-cache": true
        }
      },
      fetchPolicy: "no-cache",
      mutation: deleteRecordMutation,
      variables: {
        authToken: StoreAuthentication.getGraphqlAuthToken,
        appId: APP_ID_GL_AUFTRAEGE,
        recordIds: attrs.recordIds
      }
    });
  }

  @Action
  public async updateAssignmentsStatus(records: LivingAppsAssignmentRecord[]) {
    await StoreAssignmentsStatus.initRecords();
    const statusRecords: any = StoreAssignmentsStatus.getRecords;
    let realStatusRecords: any = null;
    for (const record of records) {
      if (record.id) {
        const isPresent: boolean = statusRecords ? Object.keys(statusRecords).includes(record.id as string) : false;
        if (!realStatusRecords) {
          realStatusRecords = {};
        }
        realStatusRecords[record.id] = isPresent ? statusRecords[record.id] : true;
      }
    }
    await StoreAssignmentsStatus.updateRecords(realStatusRecords);
  }

  @Action
  public async updateAssignmentsAdviceStatus(records: LivingAppsAssignmentRecord[]) {
    await StoreAssignmentsAdviceStatus.initRecords(records);
    const adviceRecords: AssignmentAdviceData[] | null = StoreAssignmentsAdviceStatus.getRecords;
    const realAdviceRecords: AssignmentAdviceData[] = [];
    if (adviceRecords) {
      for (const advRecord of adviceRecords) {
        if (advRecord.assigned) {
          const assignedDate = advRecord.assignedDate ? new Date(advRecord.assignedDate).getTime() : new Date(advRecord.cdate).getTime();
          const advRecordCDateTimestamp = new Date(assignedDate).getTime();
          const today = new Date().getTime();
          // Check if assignment is not older then one week.
          if (advRecordCDateTimestamp + 604800000 > today) {
            realAdviceRecords.push(advRecord);
          }
        } else {
          for (const record of records) {
            if (record.id === advRecord.record.id) {
              if (record.mitarbeiter && record.mitarbeiter.value) {
                StoreAssignmentsAdviceStatus.setRecordAssignedData(advRecord);
                realAdviceRecords.push(advRecord);
              } else {
                const advRecordCDateTimestamp = new Date(advRecord.cdate).getTime();
                const today = new Date().getTime();
                // Check if assignment is not older then two weeks.
                if (advRecordCDateTimestamp + 1209600000 > today) {
                  realAdviceRecords.push(advRecord);
                }
              }
            }
          }
        }
      }
      await StoreAssignmentsAdviceStatus.updateRecords(realAdviceRecords);
    }
  }
}
