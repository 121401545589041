










































import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import store, { StoreCostcentres } from "@/store";
import Validation from "@/mixins/Validation.vue";

@Component
export default class LoginForm extends Mixins(Validation) {
  [index: string]: any;
  @Prop(Function) public dismissModal: any;

  public formUsername: string = "";
  public formPassword: string = "";
  public formCostcentre: string = "";
  public formErrors: any = {
    username: "",
    password: "",
    costcentre: "",
  };

  public get errors(): boolean {
    for (const key in this.formErrors) {
      if (this.formErrors.hasOwnProperty(key)) {
        return this.formErrors[key].length ? true : false;
      }
    }
    return false;
  }

  public get username(): string {
    return this.formUsername;
  }

  public set username(value: string) {
    this.formUsername = value;
    (async () => {
      this.formErrors.username = await this.validate(value, "required|email", "Benutzername");
    })();
  }

  public get password(): string {
    return this.formPassword;
  }

  public set password(value: string) {
    this.formPassword = value;
    (async () => {
      this.formErrors.password = await this.validate(value, "required|min:5", "Passwort");
    })();
  }

  public get costcentre(): string {
    return this.formCostcentre;
  }

  public set costcentre(value: string) {
    this.formCostcentre = value;
  }

  public async login() {
    this.formErrors.costcentre = "";
    if (this.costcentre) {
      await store.dispatch("storeCostcentres/getDataFromEndpoint");
      const costcentre = StoreCostcentres.costcentreForCode(this.costcentre);
      if (!costcentre) {
        this.formErrors.costcentre = "Keine gültige Kostenstelle!";
      }
      if (!this.errors && costcentre) {
        await store.dispatch("storeAuthentication/requestAuthToken", {
          username: this.username,
          password: this.password,
          costcentre,
          app: this,
        });
      }
    } else {
      if (!this.errors) {
        await store.dispatch("storeAuthentication/requestAuthToken", {
          username: this.username,
          password: this.password,
          costcentre: this.costcentre,
          app: this,
        });
      }
    }
  }

  public handleLoginStatusMessage(issue: any) {
    if (issue) {
      return this.$ionic.alertController
        .create({
          header: "Login fehlgeschlagen",
          message: "Benutzername oder Passwort waren nicht korrekt.",
          buttons: ["OK"],
        })
        .then((a) => a.present());
    } else {
      this.dismissModal();
    }
  }
}
