

























import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import GlobalUser from "@/mixins/GlobalUser.vue";
import { LivingAppsAssignmentRecord, AssignmentAdviceData } from "@/types";
import store, { StoreAssignments, StoreAssignmentsStatus } from "../store";
import { EventBus } from "@/main";
import NoListEntriesMessage from "@/components/NoListEntriesMessage.vue";
import localforage from "localforage";

@Component({
  components: {
    NoListEntriesMessage,
  },
})
export default class AssignmentsList extends Mixins(GlobalUser) {
  @Prop() public identifier!: string;
  @Prop() public title?: string;
  @Prop() public titleIcon?: string;
  @Prop() public records!: AssignmentAdviceData[];
  @Prop({ type: Boolean, default: false }) public noOptions?: boolean;

  public storeAssignments = StoreAssignments;

  public getDetailRoute(record: LivingAppsAssignmentRecord): string {
    if (record.typ) {
      if (record.typ.value[0].key === "wartung") {
        return "maintenance_detail";
      }
      return "repairs_detail";
    }
    return "main";
  }

  public getRecordById(recordId: string) {
    for (const record of this.storeAssignments.getRecords) {
      if (record.id === recordId) {
        return record;
      }
    }
    return null;
  }

  public expireDate(record: LivingAppsAssignmentRecord): string {
    if (record.faellig_bis) {
      const date = new Date(`${record.faellig_bis.value} 00:00:00`).toLocaleDateString();
      return date;
    }
    return "";
  }

  public pillColor(record: LivingAppsAssignmentRecord): number {
    if (record.faellig_bis && record.angelegt_am) {
      const now = new Date().getTime();
      const created = new Date(record.angelegt_am.value as string).getTime();
      const expires = new Date(`${record.faellig_bis.value} 00:00:00`).getTime();
      const timeFrame = expires - created;
      const timeFramePos = now - created;
      if (timeFrame * 0.25 > timeFramePos) {
        return 0;
      } else if (timeFrame * 0.75 > timeFramePos) {
        return 1;
      } else {
        return 2;
      }
    }
    return 0;
  }

  public statusForRecord(record: LivingAppsAssignmentRecord): string {
    if (record.typ.value[0].key !== "bericht") {
      const assignmentId = record.auftrag_id ? (record.auftrag_id.value as string) : "";
      return StoreAssignments.getAssignmentStatus(assignmentId, this.getUserId()) as string;
    }
    return "neu";
  }

  public handleClick(route: string, id: string) {
    const rp: any = {
      name: route,
      ...(() => {
        if (id) {
          return { params: { id } };
        }
        return null;
      })(),
    };
    this.$router.push(rp);
  }

  public isOfflineItem(recordId: string) {
    if (recordId && recordId.includes("__cached__")) {
      return true;
    }
    return false;
  }

  public isUnread(recordId: string) {
    if (recordId) {
      const assignmentsStatus = StoreAssignmentsStatus.getRecords;
      return assignmentsStatus[recordId];
    }
    return false;
  }

  public saveOfflineItem(recordId: string) {
    EventBus.$emit("saveOfflineItem", this, recordId);
  }

  public async saveOfflineItemAction(recordId: string) {
    const record = this.getRecordById(recordId);
    if (record) {
      await store.dispatch("storeAssignments/storeRecordToLivingApps", {
        record,
        app: this,
        eventBus: EventBus,
      });
    }
  }

  public statusAction(record: LivingAppsAssignmentRecord) {
    EventBus.$emit("startAssignment", this, record);
  }
}
