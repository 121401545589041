



















































import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import GlobalUser from "@/mixins/GlobalUser.vue";
import { getModule } from "vuex-module-decorators";
import { StoreCcmaintenance, StoreAuthentication } from "@/store";
import {
  AreaGroupedRecords,
  LivingAppsCcmaintenanceRecord,
  LivingAppsEmployeeRecord
} from "@/types";
import { EventBus, PeriodTypes } from "@/main";
import NoListEntriesMessage from "@/components/NoListEntriesMessage.vue";
import Utils from "../mixins/Utils.vue";
import { DatetimeOptions } from "@ionic/core";

@Component({
  components: {
    NoListEntriesMessage
  }
})
export default class CcmaintenanceDetail extends Mixins(GlobalUser, Utils) {
  public get type(): string {
    let str = "";
    switch (this.$route.params.type) {
      case "d":
        str = "täglich";
        break;
      case "w":
        str = "wöchentlich";
        break;
      case "m":
        str = "monatlich";
        break;
      case "y":
        str = "Jährlich";
        break;
    }
    return str;
  }

  public getDate(dateStr: string): string {
    const dOpts: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    };
    return new Date(dateStr).toLocaleString("de-DE", dOpts);
  }

  public get records(): LivingAppsCcmaintenanceRecord[] {
    const realRecords: LivingAppsCcmaintenanceRecord[] = [];
    const type = PeriodTypes[this.$route.params.type];
    for (const record of StoreCcmaintenance.getRecords) {
      if (record.wartungszyklus === type) {
        realRecords.push(record);
      }
    }
    realRecords.sort((a, b) => {
      return (
        (((a.lfdnummer > b.lfdnummer) as unknown) as number) -
        (((a.lfdnummer < b.lfdnummer) as unknown) as number)
      );
    });
    return realRecords;
  }

  public get areaGroupedRecords(): AreaGroupedRecords[] {
    let grpData: AreaGroupedRecords | null = null;
    const records = this.records;
    const groupedRecords: AreaGroupedRecords[] = [];
    for (const record of records) {
      grpData = null;
      const groupTitle: string = record.bereich || "__no_area__";
      for (const group of groupedRecords) {
        if (group.title === groupTitle) {
          grpData = group;
          break;
        }
      }
      if (grpData) {
        grpData.items.push(record);
      } else {
        const group: AreaGroupedRecords = {
          title: groupTitle,
          items: [record]
        };
        groupedRecords.push(group);
      }
    }
    groupedRecords.sort((a, b) => {
      return (
        (((a.title > b.title) as unknown) as number) -
        (((a.title < b.title) as unknown) as number)
      );
    });
    return groupedRecords;
  }

  public checkRecord(record: LivingAppsCcmaintenanceRecord) {
    const date: string = this.getISOWithTimezoneString();
    const user: LivingAppsEmployeeRecord | null =
      StoreAuthentication.getCostcentreOperator;
    if (user && user.kuerzel) {
      StoreCcmaintenance.updateRecords({
        id: record.id,
        date,
        user: user.kuerzel.value,
        eventBus: EventBus,
        app: this
      });
    }
  }

  public handleClick(route: string) {
    this.$router.push(route);
  }
}
