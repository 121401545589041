














































import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import Utils from "@/mixins/Utils.vue";
import GlobalUser from "@/mixins/GlobalUser.vue";
import { LivingAppsConsumptionRecord, LivingAppsFileControl, LivingAppsRecordValue } from "@/types";
import store, { StoreConsumptions, StoreLogging } from "@/store";
import { saveConsumptionsRecordMutation } from "@/store/graphql/SaveConsumptionsRecord.graphql";
import { EventBus } from "@/main";
import AudioRecordingInput from "@/components/AudioRecordingInput.vue";

@Component({
  components: {
    AudioRecordingInput,
  },
})
export default class AddEmployeeForm extends Mixins(Utils, GlobalUser) {
  @Prop() public employeeRecord!: LivingAppsConsumptionRecord;
  @Prop() public assignmentId!: string;
  @Prop(Function) public dismissModal: any;

  public disable: boolean = false;

  public formMessage: string = "";
  public formAudio: File | LivingAppsFileControl | null = null;

  public get message() {
    return this.formMessage;
  }

  public set message(val: string) {
    this.formMessage = val;
  }

  public get audio(): File | LivingAppsFileControl | null {
    return this.formAudio;
  }

  public set audio(value: File | LivingAppsFileControl | null) {
    this.formAudio = value;
  }

  public handleRecordStarted() {
    this.disable = true;
  }

  public handleRecordedAudio(file: File) {
    this.audio = file;
    this.disable = false;
  }

  public deleteAudio() {
    this.audio = null;
  }

  public cancel() {
    this.dismissModal();
  }

  public laRecordWrapper(value: string | null): LivingAppsRecordValue | null {
    if (value) {
      return { value };
    }
    return null;
  }

  public async addEmployee() {
    this.disable = true;

    const lc = await this.$ionic.loadingController.create({
      message:
        "Mitarbeiter wird eingeladen. Bitte warten sie den Vorgang ab und schalten sie das Handy nicht aus!",
    });
    lc.present();
    try {
      let dispatchStr: string;
      const data: LivingAppsConsumptionRecord = {};
      const mutation = saveConsumptionsRecordMutation;
      const employeeId = this.employeeRecord.ma_id ? (this.employeeRecord.ma_id.value as string) : "";
      const firstname = this.employeeRecord.vorname ? (this.employeeRecord.vorname.value as string) : "";
      const surname = this.employeeRecord.nachname ? (this.employeeRecord.nachname.value as string) : "";
      data.auftrag_id = this.laRecordWrapper(this.assignmentId);
      data.mitarbeiter_id = this.laRecordWrapper(employeeId);
      data.mitarbeitername = this.laRecordWrapper(`${firstname} ${surname}`);

      data.mitarbeiterstatus = {
        value: `Hinzugefügt durch ${this.getUserSymbol()}`,
      };
      data.mitarbeiterstatustext = { value: this.message };
      data.audio = { value: this.audio };
      const employeeAssignmentBinding: LivingAppsConsumptionRecord | null =
        StoreConsumptions.getEmployeeAssignmentBinding(this.assignmentId, employeeId);
      if (employeeAssignmentBinding) {
        data.id = employeeAssignmentBinding.id;
        data.inaktiv2 = { value: false };
        dispatchStr = "storeConsumptions/updateRecordData";
      } else {
        dispatchStr = "storeConsumptions/createRecordData";
      }
      await store.dispatch(dispatchStr, {
        mutation,
        data,
        app: this,
        eventBus: EventBus,
      });
      lc.dismiss();
      this.dismissModal();
    } catch (error) {
      const data: any = {
        title: `Invite Employee (${navigator.onLine ? "Online" : "offline"})`,
        error,
      };
      StoreLogging.push(data);
    }
  }
}
