










import GlobalUser from "@/mixins/GlobalUser.vue";
import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import { EventBus } from "@/main";
import ClipboardUtils from "@/mixins/ClipboardUtils.vue";
import { LivingAppsAssignmentRecord } from "@/types";

@Component
export default class ClipboardFormFieldIcon extends Mixins(GlobalUser, ClipboardUtils) {
  @Prop(String) public fieldName!: string;
  @Prop(Boolean) public insertFirst!: boolean;
  @Prop() public assiRecord!: LivingAppsAssignmentRecord;
  @Prop(Object) public reportRecord!: LivingAppsAssignmentRecord;
  @Prop(String) public itemType!: string;

  public itemsForAssi(): boolean {
    if (this.assiRecord && this.hasClipboardItemsForAssi(this.assiRecord.id as string, this.itemType)) {
      return true;
    }
    return false;
  }

  public get getColorForClipboardIcon(): string {
    if (this.itemsForAssi()) {
      return "success";
    }
    return "medium";
  }

  public showClipboard() {
    const useRadio = true;
    const segmentStart: string = this.itemsForAssi() ? "inAssignment" : "";
    EventBus.$emit(
      "showClipboard",
      this.insertFirst,
      this.assiRecord,
      this.reportRecord,
      this.itemType,
      useRadio,
      segmentStart,
      this.fieldName
    );
  }
}
