import gql from "graphql-tag";
export const getCostcentreRecordsQuery = gql`
  query GetRecords($authToken: String, $appId: String!) {
    livingapi(authToken: $authToken) {
      viewtemplateData(id: $appId) {
        app {
          records {
            id
            anl_id: value(identifier: "anl_id") {
              ... on ValueTypeNumber {
                value
              }
            }
            kostenstelle: value(identifier: "kostenstelle") {
              ... on ValueTypeString {
                value
              }
            }
            beschreibung: value(identifier: "beschreibung") {
              ... on ValueTypeString {
                value
              }
            }
            code: value(identifier: "code") {
              ... on ValueTypeString {
                value
              }
            }
          }
        }
      }
    }
  }
`;
