




























































import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import Utils from "@/mixins/Utils.vue";
import { LivingAppsConsumptionRecord, LivingAppsFileControl } from "@/types";
import store, { StoreAssignments, StoreConsumptions, StoreLogging } from "@/store";
import { saveConsumptionsRecordMutation } from "@/store/graphql/SaveConsumptionsRecord.graphql";
import { EventBus } from "@/main";
import AudioRecordingInput from "@/components/AudioRecordingInput.vue";

@Component({
  components: {
    AudioRecordingInput,
  },
})
export default class StatusForm extends Mixins(Utils) {
  @Prop() public record!: LivingAppsConsumptionRecord;
  @Prop(Function) public dismissModal: any;

  public disable: boolean = false;
  public formMessage: string = "";
  public formStatus: string = "Fertig";
  public formAudio: File | LivingAppsFileControl | null = null;

  public get message() {
    return this.formMessage;
  }

  public set message(val: string) {
    this.formMessage = val;
  }

  public get status() {
    return this.formStatus;
  }

  public set status(val: string) {
    this.formStatus = val;
  }

  public get audio(): File | LivingAppsFileControl | null {
    return this.formAudio;
  }

  public set audio(value: File | LivingAppsFileControl | null) {
    this.formAudio = value;
  }

  public handleRecordStarted() {
    this.disable = true;
  }

  public handleRecordedAudio(file: File) {
    this.audio = file;
    this.disable = false;
  }

  public deleteAudio() {
    this.audio = null;
  }

  public cancel() {
    this.disable = true;
    this.dismissModal();
  }

  public async statusForAssignment() {
    this.disable = true;

    const lc = await this.$ionic.loadingController.create({
      message:
        "Ihr Status wird gespeichert. Bitte warten sie den Vorgang ab und schalten sie das Handy nicht aus!",
    });
    lc.present();
    try {
      const consumptionData = { ...this.record };
      consumptionData.mitarbeiterstatus = { value: this.status };
      consumptionData.mitarbeiterstatustext = { value: this.message };
      consumptionData.audio = { value: this.audio };
      consumptionData.ende = { value: this.getISOWithTimezoneString() };
      await store.dispatch("storeConsumptions/updateRecordData", {
        mutation: saveConsumptionsRecordMutation,
        data: consumptionData,
        app: this,
        eventBus: EventBus,
      });
      await StoreAssignments.deleteScannedRecord();
      // EventBus.$emit("refreshAll", {
      //   reload: true,
      //   app: this,
      //   event: null
      // });
      lc.dismiss();
      this.dismissModal();
      if (this.$route.name !== "main") {
        this.$router.push("main");
      }
    } catch (error) {
      const data: any = {
        title: `Assignment status (${navigator.onLine ? "Online" : "offline"})`,
        error,
      };
      StoreLogging.push(data);
    }
  }
}
