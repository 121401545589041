import gql from "graphql-tag";
export const saveRecordMutation = gql`
  mutation SaveRecord(
    $authToken: String
    $appId: String!
    $id: String
    $template: String
    $detail: Boolean
    $auftrag_id: String
    $offline_id: String
    $adhoc: Boolean!
    $titel: String
    $typ: [InputFieldLookup]
    $status: [InputFieldLookup]
    $mitarbeiter: String
    $anl_id: String
    $nummer: String
    $beschreibung: String
    $upload2: InputFieldFile
    $upload_typ: String
    $audio: InputFieldFile
    $angelegt_am: String
    $prioritaet: String
    $angelegt_von: String
    $faellig_bis: String
  ) {
    saveRecord(
      authToken: $authToken
      id: $appId
      template: $template
      detail: $detail
      input: {
        id: $id
        fields: [
          { identifier: "auftrag_id", value: $auftrag_id }
          { identifier: "offline_id", value: $offline_id }
          { identifier: "titel", value: $titel }
          { identifier: "mitarbeiter", value: $mitarbeiter }
          { identifier: "anl_id", value: $anl_id }
          { identifier: "nummer", value: $nummer }
          { identifier: "beschreibung", value: $beschreibung }
          { identifier: "upload_typ", value: $upload_typ }
          { identifier: "prioritaet", value: $prioritaet }
          { identifier: "angelegt_von", value: $angelegt_von }
        ]
        fileFields: [
          { identifier: "upload2", value: $upload2 }
          { identifier: "audio", value: $audio }
        ]
        booleanFields: [{ identifier: "adhoc", value: $adhoc }]
        dateFields: [
          { identifier: "angelegt_am", value: $angelegt_am }
          { identifier: "faellig_bis", value: $faellig_bis }
        ]
        lookupFields: [{ identifier: "typ", items: $typ }, { identifier: "status", items: $status }]
      }
    ) {
      record {
        id
        auftrag_id: value(identifier: "auftrag_id") {
          ... on ValueTypeString {
            value
          }
        }
        offline_id: value(identifier: "offline_id") {
          ... on ValueTypeString {
            value
          }
        }
        adhoc: value(identifier: "adhoc") {
          ... on ValueTypeBool {
            value
          }
        }
        titel: value(identifier: "titel") {
          ... on ValueTypeString {
            value
          }
        }
        typ: value(identifier: "typ") {
          ... on ValueTypeLookUpItem {
            value {
              key
              label
            }
          }
        }
        status: value(identifier: "status") {
          ... on ValueTypeLookUpItem {
            value {
              key
              label
            }
          }
        }
        mitarbeiter: value(identifier: "mitarbeiter") {
          ... on ValueTypeString {
            value
          }
        }
        anl_id: value(identifier: "anl_id") {
          ... on ValueTypeString {
            value
          }
        }
        nummer: value(identifier: "nummer") {
          ... on ValueTypeString {
            value
          }
        }
        beschreibung: value(identifier: "beschreibung") {
          ... on ValueTypeString {
            value
          }
        }
        upload2: value(identifier: "upload2") {
          ... on ValueTypeFile {
            value {
              id
              url
              filename
              mimetype
              width
              height
              createdat
            }
          }
        }
        upload_typ: value(identifier: "upload_typ") {
          ... on ValueTypeString {
            value
          }
        }
        audio: value(identifier: "audio") {
          ... on ValueTypeFile {
            value {
              id
              url
              filename
              mimetype
              createdat
            }
          }
        }
        angelegt_am: value(identifier: "angelegt_am") {
          ... on ValueTypeDateTime {
            value
          }
        }
        prioritaet: value(identifier: "prioritaet") {
          ... on ValueTypeString {
            value
          }
        }
        angelegt_von: value(identifier: "angelegt_von") {
          ... on ValueTypeString {
            value
          }
        }
        faellig_bis: value(identifier: "faellig_bis") {
          ... on ValueTypeDate {
            value
          }
        }
      }
    }
  }
`;
