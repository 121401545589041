
















































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  LivingAppsAssignmentRecord,
  LivingAppsRecordLookupValue,
  LivingAppsLookupItem
} from "@/types";
import { StoreAuthentication, StoreCostcentres } from "@/store";

@Component
export default class MaintenanceDetailData extends Vue {
  @Prop() public record?: LivingAppsAssignmentRecord | null;
  @Prop() public compact?: boolean;

  public get costcentre() {
    return StoreAuthentication.getCostcentre;
  }

  public get getPrio() {
    const mapping: string[] = ["niedrig", "mittel", "hoch"];
    if (this.record && this.record.prioritaet) {
      return mapping[
        parseInt(
          (this.record.prioritaet.value as LivingAppsLookupItem[])[0].label,
          10
        ) - 1
      ];
    }
    return "Keine Priorisierung";
  }

  public codeForCostcentre(costcentreId: string): string {
    return StoreCostcentres.codeForCostcentre(costcentreId);
  }

  public descForCostcentre(costcentreId: string): string {
    return StoreCostcentres.descForCostcentre(costcentreId);
  }

  public getImageUrl(name: string): string | null {
    if (this.record) {
      const img = this.record[name];
      if (img.value) {
        if (img && img.value instanceof File) {
          return URL.createObjectURL(img.value);
        } else {
          if (img.value.url) {
            return "https://my.living-apps.de" + img.value.url;
          }
        }
      }
    }
    return null;
  }
}
