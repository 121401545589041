import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store, { StoreAuthentication } from "@/store";
import localforage from "localforage";
import { AssignmentAdviceData, LivingAppsAssignmentRecord } from "@/types";

@Module({ store, namespaced: true, name: "storeAssignmentsAdviceStatus" })
export default class AssignmentsAdviceStatus extends VuexModule {
  public records: AssignmentAdviceData[] | null = null;
  public _IDB_KEY_RECORDS: string = "assignmentsadvicestatus";

  public get IDB_KEY_RECORDS(): string {
    return this._IDB_KEY_RECORDS;
  }

  public get getRecords() {
    return this.records;
  }

  @Mutation
  public setRecords(records: AssignmentAdviceData[] | null) {
    if (records) {
      this.records = records.sort(
        (a, b) => {
          const timeA: number = new Date(a.cdate).getTime();
          const timeB: number = new Date(b.cdate).getTime();
          return (
            (((timeA < timeB) as unknown) as number) -
            (((timeA > timeB) as unknown) as number)
          );
        }
      );
    }
  }

  @Mutation
  public setRecordAssignedData(record: AssignmentAdviceData) {
    record.assigned = true;
    record.assignedDate = new Date().toISOString();
  }

  @Action
  public async initRecords(records: LivingAppsAssignmentRecord[]) {
    const adviceRecords: AssignmentAdviceData[] | null = await localforage.getItem(this.context.getters.IDB_KEY_RECORDS);
    const realRecords: AssignmentAdviceData[] = [];
    const existingRecords: string[] = [];
    if (adviceRecords) {
      for (const advRecord of adviceRecords) {
        if (advRecord.record.id) {
          existingRecords.push(advRecord.record.id);
          realRecords.push(advRecord);
        }
      }
    }


    for (const record of records) {
      if (record.maschinenfuehrer && !record.adhoc && !record.mitarbeiter && record.anl_id && record.anl_id.value === StoreAuthentication.getCostcentre) {
        if (record.id && !existingRecords.includes(record.id)) {
          const advdata: AssignmentAdviceData = {
            cdate: (record.angelegt_am ? record.angelegt_am.value : "") as string,
            record,
            assigned: false
          };
          realRecords.push(advdata);
        }
      }
    }
    await this.updateRecords(realRecords);
  }

  @Action
  public async addAssignmentAdvice(advice: AssignmentAdviceData) {
    let idbData: any = await localforage.getItem(this.IDB_KEY_RECORDS);
    if (idbData) {
      idbData.push(advice);
    } else {
      idbData = [advice];
    }
    await localforage.setItem(this.IDB_KEY_RECORDS, idbData);
    this.setRecords(idbData);
  }

  @Action
  public async updateRecords(records: AssignmentAdviceData[]) {
    await localforage.setItem(this.context.getters.IDB_KEY_RECORDS, records);
    this.setRecords(records);
  }
}
