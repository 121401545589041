import { LivingAppsClipboardRecord, ClipboardAssigmentQueue, ClipboardAssigmentQueueRecord, ClipboardSortedTypes } from "@/clipboardTypes";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { client } from "@/utils/client";
import store, { APP_ID_GL_MITARBEITER_ZWISCHENABLAGE, StoreAuthentication } from "@/store";
import localforage from "localforage";
import uniqid from "uniqid";
import { getClipboardRecordsQuery } from "@/store/graphql/GetClipboardRecords.graphql";
import { saveClipboardRecordMutation } from "@/store/graphql/SaveClipboardRecord.graphql";
import { archiveClipboardRecordMutation } from "@/store/graphql/ArchiveClipboardRecord.graphql";

@Module({ store, namespaced: true, name: "storeClipboard" })
export default class Clipboard extends VuexModule {
  public assignedQueue: ClipboardAssigmentQueue = {
    note: [],
    image: [],
    audio: []
  };
  public choosedRecords: LivingAppsClipboardRecord[] = [];
  public records: LivingAppsClipboardRecord[] = [];
  public _IDB_KEY_RECORDS: string = "clipboard";

  public get IDB_KEY_RECORDS(): string {
    return this._IDB_KEY_RECORDS;
  }

  public get recordsLength(): number {
    return this.records.length;
  }

  public get getRecords(): (itemType?: string) => LivingAppsClipboardRecord[] {
    return (itemType?: string): LivingAppsClipboardRecord[] => {
      const realRecords: LivingAppsClipboardRecord[] = [];
      if (!itemType) {
        return this.records;
      }
      for (const record of this.records) {
        if (itemType === "note" && record.notiz?.value && !record.bild?.value && !record.sprachnachricht?.value) {
          realRecords.push(record);
        } else if (itemType === "image" && record.bild?.value) {
          realRecords.push(record);
        } else if (itemType === "audio" && record.sprachnachricht?.value) {
          realRecords.push(record);
        }
      }
      return realRecords;
    };
  }

  public get getRecordById(): (recordId: string) => LivingAppsClipboardRecord | null {
    return (recordId: string): LivingAppsClipboardRecord | null => {
      for (const record of this.records) {
        if (record.id === recordId) {
          return record;
        }
      }
      return null;
    };
  }

  public get getChoosedRecords(): LivingAppsClipboardRecord[] {
    return this.choosedRecords;
  }

  @Mutation
  public setRecordsData(records: LivingAppsClipboardRecord[]) {
    this.records = records;
  }

  @Mutation
  public setChoosedRecordsData(records: LivingAppsClipboardRecord[]) {
    this.choosedRecords = records;
  }

  @Mutation
  public setAssignedQueueForItem(attrs: any) {
    const itemType: string = attrs.itemType;
    const fieldName: string = attrs.fieldName;
    const record: LivingAppsClipboardRecord = attrs.record;
    const itemsForType = this.assignedQueue[itemType];
    const realItemsForType: ClipboardAssigmentQueueRecord[] = [];
    let found = false;
    for (const item of itemsForType) {
      if (item.fieldName === fieldName) {
        if (record) {
          item.recordId = record.id as string;
          realItemsForType.push(item);
        }
        found = true;
      } else {
        realItemsForType.push(item);
      }
    }
    if (!found && record) {
      const newItem: ClipboardAssigmentQueueRecord = {
        fieldName,
        recordId: record.id as string
      };
      realItemsForType.push(newItem);
    }
    this.assignedQueue[itemType] = realItemsForType;
  }

  @Mutation
  public resetAssignedQueueByType(type: string) {
    this.assignedQueue[type] = [];
  }

  @Action
  public async getDataFromEndpoint(attrs: any) {
    const template = "clipboard_get";
    const params = `{"m_id": ${attrs.m_id}}`;
    const res = await client.query({
      context: {
        headers: {
          "x-graphql-req-name": "clipboard",
          "x-graphql-req-renew-cache": true
        }
      },
      fetchPolicy: "no-cache",
      query: getClipboardRecordsQuery,
      variables: {
        authToken: StoreAuthentication.getGraphqlAuthToken,
        appId: APP_ID_GL_MITARBEITER_ZWISCHENABLAGE,
        template,
        params
      }
    });
    const recValue = res.data.livingapi.viewtemplateData.app.records || [];
    let realRecords: LivingAppsClipboardRecord[];

    if (res.data.fromCache) {
      realRecords = recValue;
    } else {
      const localRecords: LivingAppsClipboardRecord[] | null = await localforage.getItem(
        this.IDB_KEY_RECORDS
      );
      const offlineRecords: LivingAppsClipboardRecord[] = [];
      if (localRecords) {
        for (const record of localRecords) {
          if (record.id && record.id.includes("__cached__")) {
            offlineRecords.push(record);
          }
        }
      }
      realRecords = [...offlineRecords, ...recValue];
      await localforage.setItem(this.IDB_KEY_RECORDS, realRecords);
    }
    this.context.commit("setRecordsData", realRecords);
  }

  @Action
  public async createRecordData(attrs: any) {
    // Update indexedDB record data with form data.
    const record: LivingAppsClipboardRecord = attrs.data;
    const mutation = attrs.mutation;
    record.id = uniqid("__cached__") as string;
    record.offline_id = { value: record.id };
    const idbData: any = await localforage.getItem(this.IDB_KEY_RECORDS);
    idbData.unshift(record);
    const recs = await localforage.setItem(this.IDB_KEY_RECORDS, idbData);

    this.context.commit("setRecordsData", recs);
    attrs.app.$emit("saved");

    // Try to store the data to livingapps app.
    await this.storeRecordToLivingApps({
      mutation,
      record,
      eventBus: attrs.eventBus,
      app: attrs.app
    });
  }

  @Action
  public async deleteRecordData(attrs: any) {
    const recordIds = attrs.recordIds;
    const idbData: LivingAppsClipboardRecord[] | null = await localforage.getItem(this.IDB_KEY_RECORDS);
    const realData = [];
    if (idbData) {
      for (const record of idbData) {
        if (!recordIds.includes(record.id)) {
          realData.push(record);
        }
      }
      await localforage.setItem(this.IDB_KEY_RECORDS, realData);
      this.context.commit("setRecordsData", realData);
      this.archiveRecordsAtLivingApps({
        recordIds,
        app: attrs.app,
        eventBus: attrs.eventBus
      });
    }
  }

  @Action
  public async updateRecordData(attrs: any) {
    // Update indexedDB record data with form data.
    const idbData: any = await localforage.getItem(this.IDB_KEY_RECORDS);
    const mutation = attrs.mutation;
    const update = true;
    let record: LivingAppsClipboardRecord;
    for (record of idbData) {
      if (record.id && !record.id.includes("__cached__") && attrs.data.id.includes("__cached__")) {
        if (record.offline_id && record.offline_id.value === attrs.data.offline_id.value) {
          attrs.data.id = record.id;
        }
      }
      if (record.id === attrs.data.id) {
        for (const key in attrs.data) {
          if (attrs.data.hasOwnProperty(key)) {
            const d = attrs.data[key];
            record[key] = d;
          }
        }
        // Now we store the altered record data back into indexedDB.
        const recs = await localforage.setItem(this.IDB_KEY_RECORDS, idbData);
        this.context.commit("setRecordsData", recs);
        attrs.app.$emit("saved");
        // Try to store the data to livingapps app.
        await this.storeRecordToLivingApps({
          mutation,
          record,
          update,
          eventBus: attrs.eventBus,
          app: attrs.app,
        });
        break;
      }
    }

  }

  @Action
  public async storeRecordToLivingApps(attrs: any) {
    const template = attrs.update ? "clipboard_standard" : "clipboard_set";
    // const detail = true;
    const params = `{"m_id": ${attrs.record.m_id.value}}`;
    const record = { ...attrs.record };
    const mutation = attrs.mutation;
    let recordCacheId: string | null = null;
    if (record.id.includes("__cached__")) {
      recordCacheId = record.id;
      record.id = null;
    }

    // Sending mutation to the grapql server.
    const variables: any = {
      authToken: StoreAuthentication.getGraphqlAuthToken,
      appId: APP_ID_GL_MITARBEITER_ZWISCHENABLAGE,
      id: record.id,
      template,
      params
    };
    Object.keys(record).map((key: string) => {
      if (key !== "id") {
        if (record[key]) {
          if (record[key].value instanceof File) {
            variables[key] = { file: record[key].value };
          } else {
            variables[key] = record[key].value;
          }
        } else {
          if (key === "bild" || key === "sprachnachricht") {
            variables[key] = { file: null };
          } else {
            variables[key] = null;
          }
        }
      }
    });
    const res = await client.mutate({
      context: {
        headers: {
          "x-graphql-req-name": "clipboard",
          "x-skip-bgsync": false,
          "x-graphql-req-renew-cache": true,
          ...(() => {
            return recordCacheId ? { "x-data-cache-id": recordCacheId } : null;
          })()
        }
      },
      fetchPolicy: "no-cache",
      mutation,
      variables
    });

    // Updating indexedDB data with the new created record id, if we are online.
    const idbData: LivingAppsClipboardRecord[] | null = await localforage.getItem(
      this.IDB_KEY_RECORDS
    );
    if (!res.data.fromCache) {
      if (idbData) {
        for (const recordData of idbData) {
          if (recordData.id === recordCacheId) {
            recordData.id = res.data.saveRecord.record.id;
          }
        }
      }
      const recs = await localforage.setItem(this.IDB_KEY_RECORDS, idbData);
    }
    this.context.commit("setRecordsData", idbData);
  }

  @Action
  public async archiveRecordsAtLivingApps(attrs: any) {
    if (attrs.recordIds) {
      for (const id of attrs.recordIds) {
        const res = await client.mutate({
          context: {
            headers: {
              "x-graphql-req-name": "clipboard",
              "x-graphql-req-renew-cache": true
            }
          },
          fetchPolicy: "no-cache",
          mutation: archiveClipboardRecordMutation,
          variables: {
            authToken: StoreAuthentication.getGraphqlAuthToken,
            appId: APP_ID_GL_MITARBEITER_ZWISCHENABLAGE,
            id
          }
        });
      }
    }
  }

  @Action
  public resetAssignedQueue() {
    const aq = this.assignedQueue;
    for (const t in aq) {
      if (aq.hasOwnProperty(t)) {
        this.resetAssignedQueueByType(t);
      }
    }
  }

  @Action
  public async cleanup() {
    const aq = this.assignedQueue;
    const collectedRecordIds: string[] = [];
    for (const key in aq) {
      if (aq.hasOwnProperty(key)) {
        const typeList = aq[key];
        for (const item of typeList) {
          const record = this.getRecordById(item.recordId as string);
          if (record && (!record.permanent || !record.permanent.value)) {
            collectedRecordIds.push(item.recordId);
          }
        }
      }
    }
    if (collectedRecordIds.length) {
      await this.deleteRecordData({ recordIds: collectedRecordIds });
    }
  }

  @Action
  public async cleanupWithSortedSelection(sortedSelection: ClipboardSortedTypes) {
    const aq = sortedSelection;
    const collectedRecordIds: string[] = [];
    for (const key in aq) {
      if (aq.hasOwnProperty(key)) {
        const typeList = aq[key] as LivingAppsClipboardRecord[];
        for (const record of typeList) {
          if (record && (!record.permanent || !record.permanent.value)) {
            collectedRecordIds.push(record.id as string);
          }
        }
      }
    }
    if (collectedRecordIds.length) {
      await this.deleteRecordData({ recordIds: collectedRecordIds });
    }
  }
}
