
import { Component, Vue } from "vue-property-decorator";
import { localize, extend, validate } from "vee-validate";
import {
  required,
  email,
  min,
  min_value,
  max_value
} from "vee-validate/dist/rules";
import de from "vee-validate/dist/locale/de.json";
import { FormErrors } from "../types";

extend("required", required);
extend("email", email);
extend("min", min);
extend("min_value", min_value);
extend("max_value", max_value);

localize("de", de);

@Component
export default class Validation extends Vue {
  public async validate(
    value: string,
    rules: string,
    label: string
  ): Promise<string> {
    const result = await validate(value, rules);
    return Promise.resolve(
      result.valid ? "" : result.errors[0].replace("{field}", label)
    );
  }
}
