
import { Component, Vue } from "vue-property-decorator";
import { LivingAppsEmployeeRecord } from "@/types";
import store, { StoreAuthentication, StoreEmployees } from "@/store";

@Component
export default class GlobalUser extends Vue {
  public getUser(): LivingAppsEmployeeRecord | null {
    let email: string = StoreAuthentication.getUsername;
    if (
      StoreAuthentication.costcentre &&
      StoreAuthentication.costcentreOperator
    ) {
      if (StoreAuthentication.costcentreOperator.e_mail) {
        email = StoreAuthentication.costcentreOperator.e_mail.value as string;
      }
    }
    const employee: LivingAppsEmployeeRecord | null = StoreEmployees.getRecordByEmail(
      email
    );
    return employee;
  }

  public getUserSymbol(): string | null {
    const employee = this.getUser();
    if (employee && employee.kuerzel) {
      return (employee.kuerzel.value as string).trim().toLowerCase();
    }
    return null;
  }

  public getUserId(): string | null {
    const employee = this.getUser();
    if (employee && employee.ma_id) {
      return employee.ma_id.value as string;
    }
    return null;
  }

  public getUserFullname(): string | null {
    const employee = this.getUser();
    if (employee && employee.vorname && employee.nachname) {
      return `${employee.vorname.value} ${employee.nachname.value}`;
    }
    return null;
  }
}
