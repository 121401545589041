






















































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import store, { HOST_URL } from "@/store";
import GlobalUser from "@/mixins/GlobalUser.vue";
import Utils from "@/mixins/Utils.vue";
import NoListEntriesMessage from "@/components/NoListEntriesMessage.vue";
import AudioRecordingInput from "@/components/AudioRecordingInput.vue";
import { saveClipboardRecordMutation } from "@/store/graphql/SaveClipboardRecord.graphql";
import { EventBus } from "../main";
import {
  LivingAppsFileControl,
  LivingAppsRecordValue,
  LivingAppsLookupItem,
  LivingAppsAssignmentRecord,
} from "@/types";
import { LivingAppsClipboardRecord } from "@/clipboardTypes";

@Component({
  components: {
    NoListEntriesMessage,
    AudioRecordingInput,
  },
})
export default class ClipboardForms extends Mixins(GlobalUser, Utils) {
  [index: string]: any;
  @Prop(String) public type!: string;
  @Prop(Object) public assiRecord!: LivingAppsAssignmentRecord;

  public isDirty: boolean = false;
  public recAudio: boolean = false;

  public formNote: string = "";
  public formImage: File | LivingAppsFileControl | null = null;
  public formImagePreview: string = "";
  public formAudio: File | LivingAppsFileControl | null = null;

  public get note(): string {
    return this.formNote;
  }

  public set note(value: string) {
    this.formNote = value;
    this.isDirty = true;
  }

  public get image(): File | LivingAppsFileControl | null {
    return this.formImage;
  }

  public set image(value: File | LivingAppsFileControl | null) {
    this.formImage = value;
    this.isDirty = true;
  }

  public get audio(): File | LivingAppsFileControl | null {
    return this.formAudio;
  }

  public set audio(value: File | LivingAppsFileControl | null) {
    this.formAudio = value;
    this.isDirty = true;
  }

  public deleteFile(name: string) {
    this[name] = null;
    this.isDirty = true;
  }

  public handleRecordStart() {
    this.recAudio = true;
  }

  public handleRecordedAudio(file: File) {
    this.audio = file;
    this.recAudio = false;
  }

  public deleteAudio() {
    this.audio = null;
  }

  public getIconForMimetype(mimetype: string): string {
    if (mimetype.includes("/pdf")) {
      return require("@/assets/pdf-icon.png");
    } else if (mimetype.includes("/msword") || mimetype.includes(".wordprocessingml.document")) {
      return require("@/assets/doc-icon.png");
    } else if (mimetype.includes("/vnd.ms-excel") || mimetype.includes(".spreadsheetml.sheet")) {
      return require("@/assets/xls-icon.png");
    }
    return require("@/assets/unknown-icon.png");
  }

  public updatePreview(fileObj: File | LivingAppsFileControl | null) {
    if (fileObj) {
      if (fileObj instanceof File) {
        if (fileObj.type.includes("application/")) {
          this.formImagePreview = this.getIconForMimetype(fileObj.type);
        } else {
          const url = URL.createObjectURL(fileObj);
          this.formImagePreview = url;
        }
      } else {
        if (fileObj.mimetype && fileObj.mimetype.includes("application/")) {
          this.formImagePreview = this.getIconForMimetype(fileObj.mimetype);
        } else {
          this.formImagePreview = HOST_URL + fileObj.url;
        }
      }
    }
  }

  public laRecordWrapper(
    value: string | LivingAppsFileControl | LivingAppsLookupItem[] | File | null
  ): LivingAppsRecordValue | null {
    if (value) {
      return { value };
    }
    return null;
  }

  public async save() {
    if (this.isDirty) {
      const data: LivingAppsClipboardRecord = {};
      const userId = this.getUserId();
      const userName = this.getUserFullname();
      data.datum = this.laRecordWrapper(this.getISOWithTimezoneString());
      data.m_id = this.laRecordWrapper(userId);
      data.name_mitarbeiter = this.laRecordWrapper(userName);
      if (this.assiRecord) {
        data.record_id = this.laRecordWrapper(this.assiRecord.id as string);
        data.auf_id = this.laRecordWrapper(this.assiRecord.auftrag_id?.value as string);
        data.auf_titel = this.laRecordWrapper(this.assiRecord.titel?.value as string);
      }
      if (this.type === "note") {
        data.notiz = this.laRecordWrapper(this.formNote);
      } else if (this.type === "image") {
        data.bild = this.laRecordWrapper(this.formImage);
        data.notiz = this.laRecordWrapper(this.formNote);
      } else if (this.type === "audio") {
        data.sprachnachricht = this.laRecordWrapper(this.formAudio);
        data.notiz = this.laRecordWrapper(this.formNote);
      }
      await store.dispatch("storeClipboard/createRecordData", {
        mutation: saveClipboardRecordMutation,
        data,
        app: this,
        eventBus: EventBus,
      });
    }
    this.$emit("handle-cancel");
  }

  @Watch("image")
  protected onImageChange(
    value: File | LivingAppsFileControl | null,
    oldValue: File | LivingAppsFileControl | null
  ) {
    this.updatePreview(value);
  }
  @Watch("type")
  protected typeChange(value: string, oldValue: string) {
    this.recAudio = false;
  }
}
