import gql from "graphql-tag";
export const saveRecordMutation = gql`
  mutation SaveRecord($authToken: String, $appId: String!) {
    saveRecord(authToken: $authToken, id: $appId, input: { id: $id }) {
      record {
        id
      }
    }
  }
`;
