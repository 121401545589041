














































import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import GlobalUser from "@/mixins/GlobalUser.vue";
import { LivingAppsCcmaintenanceRecord, Period } from "@/types";
import store, { StoreCcmaintenance } from "../store";
import { EventBus, PeriodTypes } from "@/main";
import NoListEntriesMessage from "@/components/NoListEntriesMessage.vue";

@Component({
  components: {
    NoListEntriesMessage
  }
})
export default class CcmaintenanceList extends Mixins(GlobalUser) {
  @Prop() public identifier!: string;
  @Prop() public title?: string;
  @Prop() public titleIcon?: string;
  @Prop() public records!: LivingAppsCcmaintenanceRecord[];
  @Prop({ type: Boolean, default: false }) public noOptions?: boolean;

  public expireDate(record: LivingAppsCcmaintenanceRecord): string {
    // if (record.faellig_bis) {
    //   const date = new Date(
    //     `${record.faellig_bis.value} 00:00:00`
    //   ).toLocaleDateString();
    //   return date;
    // }
    return "";
  }

  public pillColor(record: LivingAppsCcmaintenanceRecord): number {
    // if (record.faellig_bis && record.angelegt_am) {
    //   const now = new Date().getTime();
    //   const created = new Date(record.angelegt_am.value as string).getTime();
    //   const expires = new Date(
    //     `${record.faellig_bis.value} 00:00:00`
    //   ).getTime();
    //   const timeFrame = expires - created;
    //   const timeFramePos = now - created;
    //   if (timeFrame * 0.25 > timeFramePos) {
    //     return 0;
    //   } else if (timeFrame * 0.75 > timeFramePos) {
    //     return 1;
    //   } else {
    //     return 2;
    //   }
    // }
    return 0;
  }

  public statusForRecord(record: LivingAppsCcmaintenanceRecord): string {
    // if (record.typ.value[0].key !== "bericht") {
    //   const assignmentId = record.auftrag_id
    //     ? (record.auftrag_id.value as string)
    //     : "";
    //   return StoreCcmaintenance.getAssignmentStatus(
    //     assignmentId,
    //     this.getUserId()
    //   ) as string;
    // }
    return "neu";
  }

  public doneInfo(type: string) {
    let overall: number = 0;
    let done: number = 0;
    for (const record of this.records) {
      if (record.wartungszyklus === PeriodTypes[type]) {
        ++overall;
        if (record.wann) {
          ++done;
        }
      }
    }
    return `${done} von ${overall}`;
  }

  public hasRecords(type: string) {
    for (const record of this.records) {
      if (record.wartungszyklus === PeriodTypes[type]) {
        return true;
      }
    }
    return false;
  }

  public handleClick(type: string) {
    const rp: any = {
      name: "cc_maintenance",
      params: { type }
    };
    this.$router.push(rp);
  }
}
