import { LivingAppsCcmaintenanceRecord, StandardAlertData } from "@/types";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store, { StoreCostcentres, StoreAuthentication, MAINTENANCE_GET_URL, MAINTENANCE_POST_URL } from "@/store";
import { Vue } from "vue/types/vue";



@Module({ store, namespaced: true, name: "storeCcmaintenance" })
export default class Ccmaintenance extends VuexModule {
  public records: LivingAppsCcmaintenanceRecord[] = [];
  public _IDB_KEY_RECORDS: string = "ccmaintenance";

  public get IDB_KEY_RECORDS(): string {
    return this._IDB_KEY_RECORDS;
  }

  public get getRecords(): LivingAppsCcmaintenanceRecord[] {
    return this.records;
  }

  @Mutation
  public setRecordsData(records: LivingAppsCcmaintenanceRecord[]) {
    records.sort((a, b) => {
      const lfnA = a.lfdnummer;
      const lfnB = b.lfdnummer;
      return (((lfnA > lfnB) as unknown) as number) - (((lfnA < lfnB) as unknown) as number);
    });
    this.records = records;
  }

  @Mutation
  public updateRecord(attrs: any) {
    const record = attrs.record;
    const user: string = attrs.user;
    const date: string = attrs.date;
    record.wann = date;
    record.wer = user;
  }


  @Action
  public async getDataFromEndpoint() {
    const ccRecordId = StoreCostcentres.costcentreRecordId(StoreAuthentication.getCostcentre);
    const req = new Request(
      `${MAINTENANCE_GET_URL}&kstnr=${ccRecordId}&zyklus=monatlich`
    );
    const res = await fetch(req);
    const data = await res.json();
    this.context.commit("setRecordsData", data);
  }

  @Action
  public async updateRecords(attrs: any) {
    const id: string = attrs.id;
    const user: string = attrs.user;
    const date: string = attrs.date;
    const eventBus: Vue = attrs.eventBus;
    const app: Vue = attrs.app;
    const records: LivingAppsCcmaintenanceRecord[] = this.getRecords;
    for (const record of records) {
      if (record.id === id) {
        await this.storeRecordToLivingApps({
          record,
          date,
          user,
          eventBus,
          app
        });
        break;
      }
    }
  }

  @Action
  public async storeRecordToLivingApps(attrs: any) {
    const record: LivingAppsCcmaintenanceRecord = attrs.record;
    const user: string = attrs.user;
    const date: string = attrs.date;
    const formData: FormData = new FormData();
    const eventBus: Vue = attrs.eventBus;
    const app: Vue = attrs.app;
    formData.set("template", "aufgabe_getan_pwa");
    formData.set("kuerzel", user);
    formData.set("wann", date);
    const req = new Request(
      `${MAINTENANCE_POST_URL}/${record.id}`,
      {
        method: "POST",
        body: formData
      }
    );
    const res = await fetch(req);
    const data = await res.json();
    if (data && data.valid) {
      this.updateRecord({
        record,
        date,
        user
      });
    } else {
      if (eventBus) {
        const opts: StandardAlertData = {
          header: "Wartungszeitraum überschritten!",
          subHeader: "Aufgabe wurd zurückgewiesen.",
          message: "Grund: Der Zeitraum für den diese Wartung angesetzt war wurde überschritten."
        };
        eventBus.$emit("standardError", opts);
        app.$router.push("main");
        eventBus.$emit("refreshAll", {
          reload: true,
          app: this,
          event: null
        });
      }
    }
  }
}
