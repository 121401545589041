import gql from "graphql-tag";
export const saveMaintenanceRecordMutation = gql`
  mutation SaveRecord(
    $authToken: String
    $appId: String!
    $id: String
    $titel: String
    $mitarbeiter: String
    $anl_id: String
    $beschreibung: String
    $prioritaet: [InputFieldLookup]
  ) {
    saveRecord(
      authToken: $authToken
      id: $appId
      input: {
        id: $id
        fields: [
          { identifier: "titel", value: $titel }
          { identifier: "mitarbeiter", value: $mitarbeiter }
          { identifier: "anl_id", value: $anl_id }
          { identifier: "beschreibung", value: $beschreibung }
        ]
        lookupFields: [{ identifier: "prioritaet", items: $prioritaet }]
      }
    ) {
      record {
        id
        auftrag_id: value(identifier: "auftrag_id") {
          ... on ValueTypeString {
            value
          }
        }
        offline_id: value(identifier: "offline_id") {
          ... on ValueTypeString {
            value
          }
        }
        titel: value(identifier: "titel") {
          ... on ValueTypeString {
            value
          }
        }
        typ: value(identifier: "typ") {
          ... on ValueTypeLookUpItem {
            value {
              key
              label
            }
          }
        }
        mitarbeiter: value(identifier: "mitarbeiter") {
          ... on ValueTypeString {
            value
          }
        }
        anl_id: value(identifier: "anl_id") {
          ... on ValueTypeString {
            value
          }
        }
        nummer: value(identifier: "nummer") {
          ... on ValueTypeString {
            value
          }
        }
        beschreibung: value(identifier: "beschreibung") {
          ... on ValueTypeString {
            value
          }
        }
        upload2: value(identifier: "upload2") {
          ... on ValueTypeFile {
            value {
              id
              url
              filename
              mimetype
              width
              height
              createdat
            }
          }
        }
        upload_typ: value(identifier: "upload_typ") {
          ... on ValueTypeString {
            value
          }
        }
        angelegt_am: value(identifier: "angelegt_am") {
          ... on ValueTypeString {
            value
          }
        }
        prioritaet: value(identifier: "prioritaet") {
          ... on ValueTypeLookUpItem {
            value {
              key
              label
            }
          }
        }
        angelegt_von: value(identifier: "angelegt_von") {
          ... on ValueTypeString {
            value
          }
        }
      }
    }
  }
`;
