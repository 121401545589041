import gql from "graphql-tag";
export const getAssignmentRecordsQuery = gql`
  query GetRecords($authToken: String, $appId: String!) {
    livingapi(authToken: $authToken) {
      viewtemplateData(id: $appId) {
        app {
          auftrag_id: control(identifier: "auftrag_id") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          offline_id: control(identifier: "offline_id") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          adhoc: control(identifier: "adhoc") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          titel: control(identifier: "titel") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          typ: control(identifier: "typ") {
            ... on LookupControlType {
              control {
                id
                type
                subtype
                lookupdata {
                  key
                  label
                }
              }
            }
          }
          status: control(identifier: "status") {
            ... on LookupControlType {
              control {
                id
                type
                subtype
                lookupdata {
                  key
                  label
                }
              }
            }
          }
          mitarbeiter: control(identifier: "mitarbeiter") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          anl_id: control(identifier: "anl_id") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          nummer: control(identifier: "nummer") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          beschreibung: control(identifier: "beschreibung") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          upload2: control(identifier: "upload2") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          upload_typ: control(identifier: "upload_typ") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          audio: control(identifier: "audio") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          angelegt_am: control(identifier: "angelegt_am") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          prioritaet: control(identifier: "prioritaet") {
            ... on LookupControlType {
              control {
                id
                type
                subtype
                lookupdata {
                  key
                  label
                }
              }
            }
          }
          angelegt_von: control(identifier: "angelegt_von") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          faellig_bis: control(identifier: "faellig_bis") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          maschinenfuehrer: control(identifier: "maschinenfuehrer") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          records {
            id
            auftrag_id: value(identifier: "auftrag_id") {
              ... on ValueTypeString {
                value
              }
            }
            offline_id: value(identifier: "offline_id") {
              ... on ValueTypeString {
                value
              }
            }
            adhoc: value(identifier: "adhoc") {
              ... on ValueTypeBool {
                value
              }
            }
            titel: value(identifier: "titel") {
              ... on ValueTypeString {
                value
              }
            }
            typ: value(identifier: "typ") {
              ... on ValueTypeLookUpItem {
                value {
                  key
                  label
                }
              }
            }
            status: value(identifier: "status") {
              ... on ValueTypeLookUpItem {
                value {
                  key
                  label
                }
              }
            }
            mitarbeiter: value(identifier: "mitarbeiter") {
              ... on ValueTypeString {
                value
              }
            }
            anl_id: value(identifier: "anl_id") {
              ... on ValueTypeString {
                value
              }
            }
            nummer: value(identifier: "nummer") {
              ... on ValueTypeString {
                value
              }
            }
            beschreibung: value(identifier: "beschreibung") {
              ... on ValueTypeString {
                value
              }
            }
            upload2: value(identifier: "upload2") {
              ... on ValueTypeFile {
                value {
                  id
                  url
                  filename
                  mimetype
                  width
                  height
                  createdat
                }
              }
            }
            upload_typ: value(identifier: "upload_typ") {
              ... on ValueTypeString {
                value
              }
            }
            audio: value(identifier: "audio") {
              ... on ValueTypeFile {
                value {
                  id
                  url
                  filename
                  mimetype
                  createdat
                }
              }
            }
            angelegt_am: value(identifier: "angelegt_am") {
              ... on ValueTypeDateTime {
                value
              }
            }
            prioritaet: value(identifier: "prioritaet") {
              ... on ValueTypeLookUpItem {
                value {
                  key
                  label
                }
              }
            }
            angelegt_von: value(identifier: "angelegt_von") {
              ... on ValueTypeString {
                value
              }
            }
            faellig_bis: value(identifier: "faellig_bis") {
              ... on ValueTypeDate {
                value
              }
            }
            maschinenfuehrer: value(identifier: "maschinenfuehrer") {
              ... on ValueTypeBool {
                value
              }
            }
          }
        }
      }
    }
  }
`;
