import gql from "graphql-tag";
export const getConsumptionRecordsQuery = gql`
  query GetRecords($authToken: String, $appId: String!) {
    livingapi(authToken: $authToken) {
      viewtemplateData(id: $appId) {
        app {
          art_id: control(identifier: "art_id") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          offline_id: control(identifier: "offline_id") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          lap_id: control(identifier: "lap_id") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          artikelnummer: control(identifier: "artikelnummer") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          bezeichnung: control(identifier: "bezeichnung") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          artikeltyp: control(identifier: "artikeltyp") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          lagerplatz: control(identifier: "lagerplatz") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          bestand: control(identifier: "bestand") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          auftrag_id: control(identifier: "auftrag_id") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          auftrag_bezeichnung: control(identifier: "auftrag_bezeichnung") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          mitarbeiter_id: control(identifier: "mitarbeiter_id") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          mitarbeitername: control(identifier: "mitarbeitername") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          material_geholt_am: control(identifier: "material_geholt_am") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          artikelmenge: control(identifier: "artikelmenge") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          begin: control(identifier: "begin") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          ende: control(identifier: "ende") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          dauer: control(identifier: "dauer") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          audio: control(identifier: "audio") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          inaktiv2: control(identifier: "inaktiv2") {
            ... on StandardControlType {
              control {
                id
                type
                subtype
              }
            }
          }
          records {
            id
            art_id: value(identifier: "art_id") {
              ... on ValueTypeString {
                value
              }
            }
            offline_id: value(identifier: "offline_id") {
              ... on ValueTypeString {
                value
              }
            }
            lap_id: value(identifier: "lap_id") {
              ... on ValueTypeString {
                value
              }
            }
            artikelnummer: value(identifier: "artikelnummer") {
              ... on ValueTypeString {
                value
              }
            }
            bezeichnung: value(identifier: "bezeichnung") {
              ... on ValueTypeString {
                value
              }
            }
            artikeltyp: value(identifier: "artikeltyp") {
              ... on ValueTypeString {
                value
              }
            }
            lagerplatz: value(identifier: "lagerplatz") {
              ... on ValueTypeString {
                value
              }
            }
            bestand: value(identifier: "bestand") {
              ... on ValueTypeNumber {
                value
              }
            }
            auftrag_id: value(identifier: "auftrag_id") {
              ... on ValueTypeString {
                value
              }
            }
            auftrag_bezeichnung: value(identifier: "auftrag_bezeichnung") {
              ... on ValueTypeString {
                value
              }
            }
            mitarbeiter_id: value(identifier: "mitarbeiter_id") {
              ... on ValueTypeString {
                value
              }
            }
            mitarbeitername: value(identifier: "mitarbeitername") {
              ... on ValueTypeString {
                value
              }
            }
            material_geholt_am: value(identifier: "material_geholt_am") {
              ... on ValueTypeDateTime {
                value
              }
            }
            artikelmenge: value(identifier: "artikelmenge") {
              ... on ValueTypeNumber {
                value
              }
            }
            begin: value(identifier: "begin") {
              ... on ValueTypeDateTime {
                value
              }
            }
            ende: value(identifier: "ende") {
              ... on ValueTypeDateTime {
                value
              }
            }
            dauer: value(identifier: "dauer") {
              ... on ValueTypeNumber {
                value
              }
            }
            audio: value(identifier: "audio") {
              ... on ValueTypeFile {
                value {
                  id
                  url
                  filename
                  mimetype
                  createdat
                }
              }
            }
            inaktiv2: value(identifier: "inaktiv2") {
              ... on ValueTypeBool {
                value
              }
            }
          }
        }
      }
    }
  }
`;
